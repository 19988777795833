.homepage-hotcategories {
  .page-banners {
  	display: flex;
  	flex-wrap: wrap;
  	margin: -10px;

  	.banner {
  		width: 50%;
  		margin: 0;
  		padding: 10px;
  		box-sizing: border-box;
  		position: relative;
  		box-sizing: border-box;
  		font-family: $font2;
  		font-size: 24px;
  		// display: flex;
		
		@include b1023 {
			font-size: 2.346vw;
		}

  		&:first-child {
  			width: 60%;
  		}

  		&:nth-child(2) {
			width: 40%;
			align-self:flex-end;
  		}

  		&:nth-child(3) {
			width: 40%;
			align-self:flex-start;
  		}

  		&:nth-child(4) {
			width: 60%;
  		}
  		&:nth-child(1),
  		&:nth-child(2),
  		&:nth-child(3),
  		&:nth-child(4) {
			@include b1023 {
				width: 50%;
			}

			@include b479 {
				width: 100%;
				font-size: 5vw;
			}
  		}
  		
  		

  		

  		&.white {
			.info-block {
				color: $w;

				.hotcategories-title {

				}
			}
  		}

  		.image-block {
  			position: relative;
  			width: 100%;
			
			img {
				width: 100%;
			}
  		}

  		.info-block {
  			// position: absolute;
  			// top: 1.8em;
  			// right: 1.7em;
  			// bottom: 10px;
  			// z-index: 1;
  			// left: 30px;
  			// top: 50%;
  			// transform: translateY(-50%);

  			.hotcategories-title {
  				position: absolute;
  				bottom: 2em;
  				right: 0;
  				display: block;
  				font-weight: bold;
				letter-spacing: 0.83em;
				text-align: left;
				text-transform: uppercase;
				z-index: 1;
  				// top: 1.8em;
  				font-size: 1em;
	  			right: 1.7em;
  			}

  			.button {
  				font-size: 1em;
  				display: flex;
  				align-items: center;
  				color: currentColor;
  				position: absolute;
  				z-index: 1;
  				top: calc(1.5em + 10px);
	  			right: calc(1.7em + 10px);

	  			span {
		  			font-size: 14px;
	  			}
  			}

  		// 	a {
  		// 		width: 142px;
				// height: 50px;
				// box-sizing: border-box;
				// padding: 0 18px;
				// border-radius: 2px;
				// background-color: rgba(135, 135, 135, 0);
				// border: solid 1px #000;
				// font-size: 14px;
				// letter-spacing: 0.7px;
				// text-align: left;
				// color: #d0021b;
				// display: flex;
				// align-items: center;
				// text-transform: uppercase;
				// transition: background-color 0.3s;

				// &:hover {
				// 	background-color: rgba(135, 135, 135, 0.3);
				// }

				// &:after {
				// 	content: '\e914';
				// 	font-family: 'mysecret';
				// 	display: inline;
				// 	font-size: 20px;
				// 	font-weight: bold;
				// 	margin-left: 8px;
				// 	margin-top: -2px;
				// 	color: #000;
				// }
  		// 	}
  		}


  	}
  }
}

.not-full-width {
  .homepage-unitopblog,
  .homepage-productslider,
  .homepage-banners-container {
	&.container {
	  max-width: 100%;
	  width: 100%;
	}
  }
}

.homepage-unitopblog {
  //padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;

  @include bp(max-width, $bp-tiny) {
	padding-top: 20px;
	padding-bottom: 20px;
  }

  .section-title {
	margin-bottom: 40px;
	padding-top: 0;

	@include bp(max-width, $bp-tiny) {
	  margin-bottom: 20px;
	}
  }

  a {
	@include trs(opacity .3s ease);

	&:hover {
	  opacity: .75;
	}
  }
}

.main-bottom-container {
  .homepage-unitopblog {
	margin: 0;
  }
}

.preface {
  margin-bottom: 60px;
  padding-top: 15px;

  @include b959 {
	padding-top: 0;
  }

  @include b767 {
  	margin-bottom: 0;
  }
}

.homepage-container-seotext {
	clear: both;
	margin-bottom: 70px;
	// font-family: ;
	font-size: 14px;
	line-height: 1.63;
	letter-spacing: 0.5px;
	color: #000000;

	.homepage-pagetext {
		// max-height: 210px;
		// overflow: auto;

		&::-webkit-scrollbar {
		  width: 4px;

		  &:horizontal {
		    height: 4px;
		  }
		}

		&::-webkit-scrollbar-track {
		  background-color: transparent;
		  //border-left: 1px solid #ccc;
		}

		&::-webkit-scrollbar-thumb {
		  background-color: #d8d8d8;
		  border-radius: 4px;

		  &:hover {
		    background-color: transparent;
		  }
		}

		ul {
			list-style: disc inside;
			margin: 0 0 20px;
		}
		ol {
			margin: 0 0 20px;
		}
	}

	.read-more {
		font-size: 14px;
		letter-spacing: 0.6px;
		// color: #d51e34;
		margin-top: 10px;
		display: inline-block;
		cursor: pointer;
		position: relative;

		// &:after {
		// 	content: '\e912';
		// 	font-family: 'mysecret';
		// 	font-size: 20px;
		// 	height: 20px;
		// 	line-height: 20px;
		// 	position: absolute;
		// 	top: 1px;
		// 	right: -30px;
		// 	transition: transform 0.3s;
		// }

		// &.open {
		// 	&:after {
		// 		transform: rotate(-180deg);
		// 	}
		// }
	}
}

.neklo-instagram-list {
	display: flex;
	flex-wrap: wrap;
	font-size: calc(40px*0.86);
	
	$width: 1440;
	@include bContainer {
		font-size: #{40/$width*100*0.86}vw;
	}
	
	$width: 959;
	@include b959 {
		font-size: #{40/$width*100*0.86}vw;
	}	

	$width: 639;
	@include b639 {
		font-size: #{40/$width*100*0.86}vw;
	}

	.li-insta-title {
		background-color: #00510e;
	}

	.insta-title {
		color: $w;
		font-family: $font2;
		font-weight: 900;
		line-height: 1.13;
		letter-spacing: 1px;
		padding: 13.88%;
	}


	li {
		width: 25%;

		@include b959 {
			width: 33.3333%;
		}

		@include b639 {
			width: 50%;
		}

		> a {
			position: relative;
			display: block;
			padding-bottom: 100%;
		}

		.neklo-instagram-image-wrap {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			&:hover {
				span {
					opacity: 1;
				}
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			span {
				position: absolute;
				display: block;
				opacity: 0;
				background-color: #1a1a1a;
				font-family: $font2;
				font-size: 16px;
				color: $w;
				padding: 15px;
				right: 0;
				bottom: 0;
				transition: opacity 0.3s;

				svg {
					stroke: currentColor;
					margin-right: 3.5px;
					stroke-width: 0;
				}
			}
		}

		.wrap-height {
			padding-bottom: 100%;
			position: relative;

			.wrap {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
			}
		}
	}
}