.block-layered-nav {
  .multiselect-child {
	display: block;
  }

  .currently {
	&+.actions {
	  a {
		font-size: 1rem;
		color: $main-color;
		font-weight: bold;

		&:hover {
		  color: $brown;
		}
	  }
	}
  }

  .btn-remove-inline {
	margin-left: 0;
	margin-right: 4px;
  }

  dl dd ol > li > a {
	padding: 0;

	.count {
		margin-left: 5px;
	}
  }

  #narrow-by-list {
	.configurable-swatch-list {
	  li {
		float: none;
		width: auto;
		margin: 0;
	  }
	}
  }

  [class^='brander_layerednavigation-attr'] {
	padding-left: 36px;
	position: relative;
  }

  .brander_layerednavigation-attr {
	padding-left: 36px;
  }

  [class^='brander_layerednavigation-attr'],
  .brander_layerednavigation-attr {
  	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: #000000;

  	&:before {
  		// content: '✓';
  		content: '';
  		background-size: contain;

  		position: absolute;
  		top: 0;
  		left: 0;
  		width: 20px;
  		height: 20px;
  		border: 1px solid rgba($b,0.2);
  		box-sizing: border-box;
  		color: transparent;
  		font-size: 20px;
  		display: flex;
  		align-items: center;
  		justify-content: center;
  	}
  }

  .brander_layerednavigation-attr-selected,
  .brander_layerednavigation-attr.checked,
  .brander_layerednavigation-attr:hover {
  	cursor: pointer;
  	
  	&:before {
  		// background: #d0021b;
  		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60' height='60'><path fill='#fff' d='M40.563 17.5l-11.525 17.605-11.3-7.395-2.737 4.18 15.483 10.137 14.262-21.787z'></path></svg>");
  		background-color: $b;
  		border-color: $b;
  		color: #fff;
  	}
  }

  .inpts-wrap {
	margin-bottom: 8px;
	font-size: 0;
	white-space: nowrap;

	.price-range-from,
	.f-to,
	.price-range-to {
	  display: inline-block;
	  font-size: 14px;
	}

	.price-range-to,
	.price-range-from {
	  width: 45%;
	}

	.f-to {
	  width: 10%;
	  text-align: center;
	}

	input {
	  width: 100%;
	  text-align: center;
	}
  }

  .price-range-btn-wrap {
	.layerednavigation-price-find {
	  width: 100%;
	}
  }

  &.layerednavigation-filters-left {
	.configurable-swatch-list {
	  > li {
		display: inline-block;
	  }

	  img {
		position: static;
		width: 20px;
		height: 20px;
	  }
	}
  }
}

.block-layered-nav .block-content > dl > dd {
	display: block;
}