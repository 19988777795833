.catalog-product-view {
  .wrapper {
    // background: #f4f4f4;

    .main {
      background: none;
    }
  }

  .main {
    padding-bottom: 0;

    .inner-container {
      overflow: hidden;
    }
  }

  .logo-wrapper--mobile {
    
  }
}

#messages_product_view {
  width: calc(100% + 140px);
  box-sizing: border-box;
  margin-left: -70px;
  background: #fff;
  box-shadow: 0 3px 5px 0px rgba(0,0,0,0.1);


  .notice-msg {
    padding: 25px 95px 25px 150px !important;
    background-position: 95px center !important;
  }
}

.product-view-form {
  display: flex;
  padding-top: 10px;

  @include b767 {
    flex-wrap: wrap;
    padding-top: 0;
  }
}

.product-view {
  > *:last-child {
    &:not(.clearer) {
      padding-bottom: 70px;
    }
  }

  h1 {
    @include b767 {
      font-size: 22px;
      margin-bottom: 0;
    }
  }

  .sku {
    margin-bottom: 0;
  }

  > form {
    background: #fff;
    box-sizing: border-box;
    // width: calc(100% + 140px);
    // padding: 70px 70px 40px;
    // margin-left: -70px;
    // margin-bottom: 70px;

    // @include bp(max-width, 960px){
    //   padding: 20px 40px 40px;
    // }

    // @include bp(max-width, $bp-tiny){
    //   margin-left: 0;
    //   padding: 20px 20px 40px;
    // }
  }

  .action-box {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .product-img-column,
  .product-shop,
  .product-secondary-column {
    margin: 0;
    box-sizing: border-box;
  }

  //------------


  .product-primary-column {
    width: 50%;
    padding-left: 20px;
    // padding-top: 20px;

    @include b767 {
      width: 100%;
    }
  }

  //------------

  .product-img-column {
    margin: 0;
    width: 50%;

    @include b767 {
      width: 100%;
    }

    .no-left-gutter {
      display: flex;
      margin: 10px 0;
      font-size: 16px;

      .sku {
        margin: 0 20px 0 0;
      }
    }

    .sticker-wrapper {
      top: 16px;

      @include cleaner;

      &.top-left {
        left: 18px;
      }

      &.top-right {
        right: 18px;
      }
    }

    .zoom-inside:hover {
      .sticker-wrapper {
        display: none;
      }
    }

    #zoom-btn {
      font-size: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: rgba(255, 255, 255, 0.3);
      transition: background 0.3s;

      &:hover {
        background: rgba(255, 255, 255, 0.7);
      }

      &:before {
        content: '\e910';
        font-family: 'mysecret';
        font-size: 32px;
      }
    }

    .img-box  {
      display: flex;

      .product-image {
        width: 73.83%;

        &.single-image {
          width: 100%;
        }
      }

      .more-images {
        // width: 25%;
        flex-grow: 1;
        margin-top: 0;
        position: relative;
        order: -1;

        .thumbnails {
          // .slides
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;

          .touch & {
            overflow-y: scroll;
          }
        
          .thumbnails-wrap {
            position: relative;
            transition: top 0.3s;
          }

          .item {
            padding-right: 10%;
            padding-bottom: 10%;

            &:last-child {
              padding-bottom: 0;
            }

            a {
              display: block;
              border: 1px solid #e4e0e5;
              // margin: 0 3px;
              position: relative;
              @include trs(border-color .3s ease);

              &:hover,
              &.active {
                border-color: #000;
              }

              img {
                width: 100%;
              }
            }
          }
        }

        .buttons {
          position: absolute;
          bottom: 0;
          display: flex;
          width: 90%;
          justify-content: space-between;
          
          &.none {
            display: none;
          }

          > div {
            width: calc(50% - 2px);
            margin: 1px;
            margin-bottom: 0;
            padding-bottom: 20%;
            background-color: rgba(#fff,0.8);
            position: relative;
            box-shadow: 0 0 5px rgba(#000, 0.5);
            cursor: pointer;

            &.none {
              .sic {
                opacity: 0.2;
              }
            }

            .wr {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.1s;

            }

            .sic {
              width: 50%;
              height: 50%;
            }
          }

          .prev {
            &:hover {
              .wr {
                transform: scale(1.1);
              }
            }
            .sic {
              transform: rotate(180deg);
            }
          }

          .next {
            &:hover {
              .wr {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      .itemslider-thumbnails {
        &.count-5,
        &.count-multi {
          max-width: none;
        }

        &.count-4 {
          max-width: none;
        }

        &.count-3 {
          max-width: none;
        }

        &.count-2 {
          max-width: none;
        }

        &.count-1 {
          display: none;
        }

        @include bp(max-width, 1400px){
          &.count-4,
          &.count-5,
          &.count-multi {
            max-width: 270px;
          }

          &.count-3 {
            max-width: 204px;
          }
        }

        @include bp(max-width, 1280px){
          &.count-3,
          &.count-4,
          &.count-5,
          &.count-multi {
            max-width: 204px;
          }
        }

        @include bp(max-width, 960px){
          &.count-2,
          &.count-3,
          &.count-4,
          &.count-5,
          &.count-multi {
            max-width: 204px !important;
          }
        }
      }

      .owl-controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .owl-buttons {
          .owl-prev,
          .owl-next {
            width: 28px;
            height: 30px;
            top: 50%;
            color: #000;
            font-size: 28px;
            transform: translate(0, -50%);

            &:before {
              content: '\e914';
              font-family: 'mysecret';
              display: block;
              transition: text-indent 0.3s;
            }

            &:hover {
              &:before {
                text-indent: 5px;
              }
            }
          }

          .owl-prev {
            transform: rotate(180deg) translate(0, 50%);
          }
        }
      }
      .owl-wrapper-outer {
        position: relative;
        z-index: 2;
      }
    }
  }

  .product-shop {
    margin: 0;

    @include b479 {
      padding-top: 0 !important;
    }

    .product-name {
      margin-bottom: 0;

      h1 {
        margin-bottom: 15px;
      }
    }

    .no-left-gutter {
      display: flex;
      align-items: baseline;

      .sku {
        color: $gray;
        margin-right: 20px;
      }

      .brand {
        font-size: 14px;
        font-family: $font2;
      }
    }

    .sku {
      margin-top: 0;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: #000000;
      text-transform: uppercase;
    }

    //tmp ----------------
    .short-description/* ,
    .sku */ {
      display: none;
    }
    //--------------------

    #product-options-wrapper {
      p.required {
        display: none;
      }

      .fancybox {
        font-size: 14px;
        color: $brown;
        line-height: 2;

        &:hover {
          color: $b;
        }
      }

      label {
        width: calc(100% - 100px);
        font-size: 14px;
        text-align: left;
        color: #000000;
        font-weight: normal;
        //margin-bottom: 15px;
        min-height: 25px;
        text-transform: uppercase;

        .select-label {
          text-transform: none;
          display: none;
        }

        span {
          display: block;
        }
      }

      /* label.required {
        width: calc(100% - 100px);
        font-size: 14px;
        text-align: left;
        color: #000000;
        font-weight: normal;
        //margin-bottom: 15px;
        min-height: 35px;
      } */

      .swatch-attr {
        min-width: 205px;

        .input-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .configurable-swatch-list {
          margin-right: 25px;

          &[id=configurable_swatch_color] {
            margin: 0 -5px;
            
            li {
              margin: 0 5px;
            
            }
          }

          li {
            // margin: 0 -1px 0 0;
            margin: 1px 1px 0;

            > a {
              margin: 0;
              border: none;
              width: 45px;
              height: 37px !important;

              .swatch-label {
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100% !important;
                font-size: 16px;
                color: #000000;
                background: $llgray;
                box-sizing: border-box;
              }
            }

            .has-image {
              width: 25px !important;
              height: 25px !important;
              border: 1px solid transparent;
              border-radius: 50%;
              box-sizing: border-box;

              .swatch-label {
                background: none;
                width: 100% !important;
                height: 100% !important;

                img {
                  position: static;
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                }
              }
            }

            &.selected,
            &.hover {
              > a {
                .swatch-label {
                  background: $b;
                  color: $w;
                }
              }

              .has-image {
                border-color: rgba(#241822,0.1);

                .swatch-label {
                  background: none;
                }
              }
            }

            &.selected {
              .has-image {
                border-color: rgba(#241822,0.5);
              }
            }

          }
        }
      }

      .how-know {
        a {
          margin: 10px 0;
          display: inline-block;
          font-size: 14px;
          line-height: 1.14;
          color: #000000;

          span {
            position: relative;
            padding-left: 50px;

            &:before {
              font-size: 36px;
              position: absolute;
              left: 0;
              top: -10px;
            }
          }
        }
      }
    }

    .price-box {
      font-size: 36px;
      letter-spacing: -0.1px;
      color: #000000;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin-bottom: 10px;
      padding-top: 0;
      padding-bottom: 0;

      @include b479 {
        margin-bottom: 5px;
      }

      .price {
        font-size: 24px;
      }

      .price-currency {
        // font-size: 18px;
      }

      .special-price {
        font-weight: bold;
        margin-right: 15px;
      }

      .old-price {
        letter-spacing: 0.7px;
        opacity: 0.4;

        .price {
          text-decoration: none;
          position: relative;
          font-size: 14px;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 1px;
            width: 100%;
            height: 1px;
            margin-top: -1px;
            background: #000;
          }
        }
      }
    }

    &.not-salable {
      .price-box {
        font-size: 36px;
        font-weight: bold;
        letter-spacing: -0.1px;
        text-align: left;
        color: #000000;
      }
    }

    .add-to-box {
      display: flex;
      flex-wrap: wrap;

      .add-to-cart {
        .btn-cart {
          min-width: 140px;
          // @extend %button;
          // height: 50px;
          // border-radius: 3px;
          // background-color: #000;
          // border-color: #000;
          // color: #ffffff;
          // margin-right: 8px;
          // margin-bottom: 8px;

          &.out-of-stock {
            opacity: 0.3;
          }
        }

        .qty-wrapper {
          display: none;
        }
      }

      .btns-set {
        a {
          @extend %button;
          min-width: 195px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-color: rgba(0, 0, 0, 0.4);
        }
      }

      @include bp(max-width, $bp-tiny){
        justify-content: center;
      }

      @include bp(max-width, 640px){
        // display: block;

        .add-to-cart .btn-cart,
        .btns-set {
          // display: block;
          // width: 100%;
          margin: 0;
        }
      }
    }

    .product-consist {
      margin-bottom: 20px;
    }

    .link-wishlist svg {
      color: $b;
      opacity: 1;
    }

    .add-to-links {
      text-transform: uppercase;
      @include cleaner;

      li {
        // margin-right: 24px;
      }

      a, button {
        &:hover {
          color: $main-color;

          .ic {
            color: $main-color;
          }
        }

        .ic {
          color: inherit;
          font-size: 44px;
        }
      }

      button {
        cursor: pointer;
        border:0;
        background: none;
        font-size: 14px;
        letter-spacing: 0.9px;

        &:hover {
          svg {
            opacity: 0;

            + svg {
              opacity: 1;
            }
          }
        }

        svg {
          transition: opacity 0.3s;

          + svg {
            margin-left: -33px;
            opacity: 0;
          }
        }
      }
    }

    .product-page-tabs-links {
      a {
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #d51e34;
        margin-right: 30px;
      }
    }

    .crosssell-color-swatches {
      margin-top: 100px;
      font-size: 14px;
      color: #000000;
      display: flex;
      flex-wrap: wrap;

      > span {
        font-size: 14px;
        color: #000000;
        display: block;
        margin-bottom: 14px;
        width: 100%;
      }

      .crosssell-color-swatch-item {
        width: 52px;
        height: 52px;
        margin-right: 6px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  .product-secondary-column {
    padding: 0;

    .action-box {
      margin: 0;
    }

    .inner {
      padding: 0;
    }

    .benefits-container {
      margin-bottom: 30px;

      ul {
        display: flex;
        flex-direction: column;
        height: 630px;

        li {
          height: 25%;
        }
      }

      .benefit {
        &:not(:first-child) {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        a {
          background: #f4f4f4;
          padding: 5px 20px;
          text-align: center;

          display: flex;
          flex-direction: column;
          //align-items: center;
          justify-content: center;

          &:hover {
            background: rgba(220, 220, 220, 1);
          }
        }

        &.benefit-counter {
          a {
            .counter-title {
              font-size: 16px;
              line-height: 1.38;
              color: #000000;
            }
          }
        }

        .img-wrap {
          width: 100%;
          margin: 0;
          display: block;
        }
        .benefit-title {
          display: none;
        }
        .benefit-text {
          font-size: 16px;
          line-height: 1.38;
          color: #000000;
        }

        .number-counter-value {
          font-size: 20px;
          font-weight: bold;
          margin-top: 10px;
        }
      }
    }



    .social-links {
      .social-list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 16px);
        margin-left: -8px;

        li {
          margin: 0 8px 8px;
        }
      }

      a {
        padding: 0;
        margin: 0;

        .ib {
          border-radius: 2px;
          border: 1px solid #6e6e6e;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 50px;
          height: 50px;
          color: #6e6e6e;

          &:before {
            font-size: 54px;
            width: 100%;
            text-align: center;
            text-indent: -3px;
          }

          &.ic-ok {
            &:before {
              text-indent: 0;
            }
          }

          &.ic-pinterest {
            &:before {
              font-size: 28px;
            }
          }
        }

        &:hover {
          .ib {
            color: #6e6e6e !important;
            background: rgba(110, 110, 110, 0.2) !important;
          }
        }
      }
    }
  }

  //-------------


  @include bp(max-width, 1180px){

    .product-secondary-column {
      width: 100%;
      margin-top: 50px;

      .benefits-container {
        ul {
          height: auto;
          display: flex;
          width: fit-content;
          margin: 0 auto;
          flex-direction: row;

          li {
            border: none;
            width: 33.33%;
            height: auto;

            &:not(:first-child){
              border-left: 1px solid rgba(0,0,0,0.1);
            }

            &.benefit-counter {
              a {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
      }

      .social-links {
        .social-list {
          justify-content: center; 
        }
      }
    }
  }

  @include bp(max-width, $bp-tiny){
    .product-shop {
      padding: 40px 0 0;
    }

    .product-secondary-column {
      //width: 100%;
      //margin-top: 50px;

      .benefits-container {
        ul {
          display: block;
          width: 100%;

          li.benefit {
            border: none;
            width: 100%;

            a {
              padding: 20px;
            }

            &:not(:first-child){
              border-top: 1px solid rgba(0,0,0,0.1);
            }
          }
        }
      }

      .social-links {
        .social-list {
          justify-content: center; 
        }
      }
    }
  }

  .ratings, .no-rating {
    margin-top: 0;
    text-align: right;
  }

  .short-description {
    padding: 20px 0 35px;
    margin: 0;
    line-height: 1.5;
  }

  .alert-stock {
    @extend %button;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    min-width: 195px;
    height: 50px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .product-type-data {
    .availability-only {
      display: none;
      background: #faf2ec;
      line-height: 36px;
      padding: 0 14px;
      border-radius: 5px;
    }

    .item-options {
      dd {
        float: none;
      }
    }
  }


  .share-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // @include bp(max-width, $bp-tiny) {
    //   text-align: center;
    // }
  }

  .social-title {
    font-size: 14px;
    color: $b;
    opacity: 0.5;
    margin-right: 10px;
  }

  .social-links {
    margin: -8px 0 0 -8px;

  }

  .social-list {
    display: flex;

    li {
      margin: 8px 0 0 8px;
    }

    a {
      border: 1px solid $b;
      cursor: pointer;

      &:hover {
        background-color: $b;
        color: $w;
      }
    }
  }

  .product-options {
    width: fit-content;
    position: relative;

    p.required {
      top: 0;
    }
  }

  .qty-wrapper {
    .ui-spinner {
      border-color: #ddd;
      @include trs(border-color 300ms ease-in-out);

      &:hover {
        border-color: #aaa;
      }

      .ui-state-default {
        border-color: #ddd;
      }

      .ui-state-hover {
        border-color: #aaa;
      }
    }

    .qty {
      margin: 0 16px 0 0;
      border: 0;
    }
  }

  #srka-product-review-list .options li .option {
    width: 18px;
    height: 17px;
    background: url(../images/rating.png) 0 0 no-repeat;
  }

  .benefits-container {
    padding: 0;

    .benefit-heading {
      display: block;
      margin-bottom: 10px;
    }

    .img-wrap {
      width: 38px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 22px;
      margin-bottom: 0;

      &+.benefit-title {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }

    //@include bp(max-width, $bp-desktop) {
    //  .benefit-title,
    //  .benefit-text {
    //    font-size: 14px;
    //    letter-spacing: -1px;
    //  }
    //}
  }


  @include bp(max-width, $bp-smallest) {
    .box-reviews {
      .form-add {
        .form-list {
          input.input-text,
          textarea {
            width: 100%;
          }
        }
      }
    }

    .action-box {
      text-align: center;
    }
  }

  .availability-only {
    &.expanded {
      .caret {
        transform: rotate(180deg);
      }
    }
  }

  .availability {
    color: $dark;
    text-transform: uppercase;
    background: #f9f8f9;
    padding: 5px;
    border-radius: 5px;
  }

  .box-additional {
    &:empty {
      display: none;
    }
  }
  

}

#review-form {
  thead,
  tbody,
  td {
    border: none;
  }

  thead {
    display: none;
  }
}

#product-review-table {
  margin-bottom: 22px;
  display: block;

  + .validate-rating {
    + .validation-advice {
      margin-left: 120px;
    }
  }

  th,
  td {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
  }

  .label {
    width: 120px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
  }

  .value {
    width: 17px;
    height: 17px;
    padding: 0;
    vertical-align: middle;
    margin: 0 3px;
    position: relative;

    &.active,
    &.current {
      input[type="radio"] {
        &+label {
          &:before {
            content: '\e224';
          }
        }
      }
    }
  }

  input[type="radio"] {
    visibility: hidden;
    opacity: 0;

    &+label {
      cursor: pointer;

      &:before {
        position: absolute;
        font-family: BaseIcons;
        content: '\e223';
        font-size: 17px;
        line-height: 1;
        color: #efa767;
        left: 0;
        top: 0;
      }
    }

    &:checked {
      &+label {
        &:before {
          content: '\e224';
        }
      }
    }
  }
}

.box-additionals-holder {
  display: table;
  table-layout: fixed;

  @include bp(max-width, $bp-tiny) {
    display: block;
  }

  .box-additionals-row {
    display: table-row;
  }

  @include cleaner;

  .box-additional {


    /* width: 100%;
    display: block;
    margin: 0;
    position: relative; */

    /* .tabs {
      width: 100%;
      position: relative;

      a {
        font-size: 1rem;
        line-height: 72px;
        text-transform: uppercase;

        
      }

      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100px;
        background: #f8f1f0;
        left: -100px;
      }
    } */

    /* &:not(.box-sidebar) {
      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100px;
        background: #fffaf6;
        left: -100px;

        @include bp(max-width, 959px) {
          display: none;
        }
      }
    } */

    /* .box-collateral {
      position: relative;

      .tabs-panels {
        padding: 54px 54px 54px 0;
        border: none;

        @include bp(max-width, 959px) {
          padding: 0;
          margin: 0 -15px;

          .acctab {
            background: #f8f1f0;
            text-align: center;
            border: none;
          }
        }

        .std {
          line-height: 1.85;
        }
      }
    } */
  }
}
.product-view .section-title {
  // margin-bottom: 35px;
}

.accor {
  .acctab {
    font-family: 'Roboto';
    +.panel {
      display: none !important;
    }

    &.active {
      &+.panel {
        display: block !important;
      }
    }
  }
}

.white-bg,
[id=product-tabs] {
  box-sizing: border-box;

  .white-bg-inner,
  .box-collateral {
    width: 100%;
  }

  @include bp(max-width, $bp-tiny){
    margin-left: 0;
    width: 100%;
    padding: 10px 0;
  }
}

[id=product-tabs] {
  margin-bottom: 0;
  padding-bottom: 10px;

  @include b767 {
    padding-bottom: 10px;
  }
}

.white-bg {
  & + .white-bg {
    padding-top: 40px;

    .section-title {
      padding-top: 0;
    }
  }
}

.box-additionals-holder {
  // margin-bottom: 70px;
}

.box-recommendations {
  .recommendations-title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .recommendations-image {
    margin-bottom: 15px;
  }
  .recommendations-text {
    display: block;
    font-size: 16px;
  }
}

[id=product-tabs] {
  .tabs,
  .tabs-panels {
    background: none !important;

    &:before {
      display: none;
    }
  }

  .tabs {
    // width: 330px;
    box-sizing: border-box;
    // padding-right: 40px;
    margin: 0 -15px;

    li {
      // clear: left;
      margin-bottom: 15px;
      // min-width: 200px;
      padding: 0 15px;

      a {
        position: relative;
        background: none;
        line-height: 18px;
        padding: 5px 0;
        display: block;
        font-size: 18px;
        font-weight: 900;
        font-family: $font2;
        // text-transform: uppercase;
        letter-spacing: 0.6px;
        color: $gray;
        // opacity: 0.31;
        @include trs(outline .3s ease);

        // &:after {
        //   content: '';
        //   position: absolute;
        //   width: 0;
        //   height: 2px;
        //   background: #000;
        //   left: 0;
        //   bottom: 0;
        //   @include trs(width .3s ease);
        // }

        &.current,
        &:hover {
          opacity: 1;
          background: none;
          color: $b;

          // &:after {
          //   width: 100%;
          // }
        }
      }
    }

  }
  .tabs-panels {
    // width: calc(100% - 330px);
    padding: 0;
    font-family: $font2;

    @include bp(max-width, $bp-small){
      width: 100%;
    }

    .panel {
      font-size: 14px;
      line-height: 1.56;
      letter-spacing: 0.5px;
      text-align: left;
      color: $b;

      iframe {
        max-width: 100%;
        //height: auto;
      }
    }

    tr {
      border-bottom: 1px solid $llgray;

      td {
        padding: 12px 25px
      }
    }
  }
}

.gen-tabs .acctab {
  background: none;

  &:hover,
  &:focus,
  &.active {
    border-color: $main-color;
    color: $main-color;
  }
}

/* .itemslider-related-wrapper {
  @include bp(max-width, 959px) {
    display: none;
  }
} */

.itemslider-related-mobile-wrapper {
  display: none;

  @include bp(max-width, 959px) {
    display: block;
  }
}


.reviews-list {
  li {
    margin: 0 0 20px;
  }

  label {
    width: 120px;
  }

  .review-title {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .review-text {
    padding: 10px 0;
    
    &+.nobr {
      font-style: italic;
    }
  }

  .input-box {
    width: calc(100% - 120px);
    float: left;
    clear: none;

    .input-text {
      max-width: 100%;
    }
  }
}

#srka-product-review-list .srka-rating-code .label {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 110px;

  &:after {
    content: ' *';
    color: #EE001C;
    font-weight: normal;
    font-family: Verdana, Tahoma, Arial, sans-serif;
    font-size: 12px;
  }

  & + .options {
    display: inline-block;
    vertical-align: middle;
    padding-top: 0;
  }
}

#oneclickbuy_form {
  width: 510px;
  max-width: 100%;
  padding: 40px;
  box-sizing: border-box;

  .popup-heading {
    h2 {
      color: #42536a;
      text-align: center;
      font-family: 'Open Sans', arial;
      margin: 0 0 10px;
      font-size: 36px;
      line-height: 1.1;
    }
  }

  .popup-content {
    //.webforms-fastbuy {
    //  padding-bottom: 35px;
    //}

    .row {
      .validation-advice {
        padding: 0;
        text-align: right;
        width: 264px;
        max-width: 100%;
        margin: 0 auto;
        color: #f68d91;
      }

      input {
        height: 44px;
        width: 264px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        border: none;
        border-bottom: 1px solid #c6cbd2;
        font-size: 14px;

        &:focus {
          box-shadow: none !important;
          border-color: $main-color;
        }

        &.validation-failed {
          border: none !important;
          border-bottom: 1px solid #ed1c24 !important;
        }
      }
    }

    .btn-holder {
      padding-top: 37px;

      .button {
        display: block;
        margin: 0 auto;
        float: none;
      }
    }
  }
}



#itemslider-upsell {
  margin-bottom: 40px;
}

#customer-reviews {
  dl {
    dl {
      padding-left: 50px;
      box-sizing: border-box;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #a399a5;
    }
  }
  .ansver-title {
    font-weight: bold;
  }
  .ansver-text {
    //
  }

  .all-button {
    font-size: 14px;
    letter-spacing: 0.6px;
    color: $b;
    margin-right: 30px;
    display: inline-block;
    margin-top: 10px;

    &:hover {
      color: $brown;
    }
  }
}

.container1-wrapper {
  margin: 15px 0;

  @include b479 {
    margin: 10px 0;
  }
}

.add-to-wrap {
  display: flex;
  align-items: center;

  @include b479 {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.link-wishlist {
  letter-spacing: 0.2px;
  font-weight: normal;
  padding: 0 25px;

  @include b479 {
    padding: 0;
  }

  svg,
  span {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    margin-right: 5px;
    stroke-width: 0;
  }
}

.price-and-add-to {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
  }

  .old-price {
    flex-grow: 1;
  }

  .new-price {
    color: $new;
    font-size: 24px;
    display: flex;
    width: 100%;
    order: 1;


    .discount-label {
      margin-right: 5px;

      &:before {
        content: '-';
      }

      &:after {
        content: ' =';
      }
    }

    .price {
      font-size: 24px;
    }
  }

  // > div:first-child {
  //   margin-right: 20px;
  // }
}

.configurable-swatch-list {
  .not-available {
    &.hover,
    &.selected {
        .x {
            background: transparent url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='none' stroke='#fff' stroke-width='1' d='M0 30l30-30z'/></svg>") no-repeat;
            background-size: 100% 100%;
        }
    }
  }
}