.catalog-category-view {
  .main {
    padding-top: 0;
    padding-bottom: 60px;
  }

  .breadcrumbs {
    
    
    position: relative;
    margin-bottom: 0;

    ul {
      position: relative;
    }
  }

  .category-title {
    margin-bottom: 30px;
    width: calc(100% - 660px);

    @include bp(max-width, 1280px){
      width: 100%;
    }

    @include b479 {
      margin-bottom: 0;
    }

    h1 {
      font-size: 25px;
      // font-weight: normal;
      // letter-spacing: 1.1px;
      // color: $b;
      // text-transform: uppercase;
      font-weight: 500;
    }
  }

  @include bp(max-width, 960px){
    .inner-container {
      padding: 0 15px;
    }
  }

  .col-main {
    width: 100%;
  }
}

.layerednavigation-index-index {
  .col-main {
    width: 100%;
  }
}



.category-products {
  position: relative;

  .toolbar {
    position: absolute;
    right: 0;
    bottom: 100%;
    font-size: 14px;
    @include cleaner;

    @include bp(max-width, 1280px){
      position: static;
    }

    @include b479 {
      margin-bottom: 0;
    }

    .sorter {
      @include b479 {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
      }
    }

    .chosen-container {
      margin-right: 0;

      .chosen-single {
        border: none;
        font-size: 12px;
        color: $b;
        padding: 0 20px 0 10px;

        div {
          width: 20px;
          border: none;
        }
      }

      .chosen-drop {
        width: auto;
        min-width: 100%;

        li {
          &.highlighted {
            font-weight: normal;
            color: #000;
          }
        }
      }

      .chosen-results {

        &::-webkit-scrollbar {
          width: 4px;

          &:horizontal {
            height: 4px;
          }
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d8d8d8;
          border-radius: 4px;

          &:hover {
            background-color: #000;
          }
        }

        li {
          padding: 8px 10px;
          font-size: 14px;
        }
      }
    }

    .sort-by {
      .chosen-container {
        min-width: 140px;
        width: auto !important;
      }

      label {
        opacity: 0.5;
        margin: 0;
      }

      > button {
        display: none;
      }
    }

    .limiter {
      margin-left: 20px;

      .chosen-container {
        min-width: 60px;
      }

      label {
        opacity: 0.5;
        margin: 0;

        @include b479 {
          line-height: 38px;
        }
      }

      .per-page {
        display: none;
      }
    }

    .sorter {
      @include bp(min-width, 1281px){
        float: right;
      }
    }

    .filter-opener {
      font-size: 14px;
      letter-spacing: 0.7px;
      // color: #d0021b;
      height: 40px;
      padding: 0 17px;
      margin: 7px 0 7px 10px;
      display: flex;
      align-items: center;
      float: right;
      // border-radius: 2px;
      text-transform: uppercase;
      // border: 1px solid $b;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        // background: #d0021b;
        // color: #fff;
        color: $brown;

        // svg {
          // color: $brown;
        // }
      }

      svg {
        margin-right: 8px;
      }

      @include bp(max-width, $bp-tiny){
        position: fixed;
        top: 50%;
        left: -4px;
        z-index: 9998;
        transform: rotate(90deg) translate(-50%, 40px);
        background: #d0021b;
        color: #fff;
        margin: 0;

        &:hover {
          background: $b;

          svg {
            color: currentColor;
          }
        }
      }
    }
  }


  .configurable-swatch-color {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 5px;
    }

    .selected {
      a {
        border-color: rgba(#241822,0.5);

        &:hover {
          border-color: rgba(#241822,0.5);
        }
      }
    }

    a {
      width: 25px !important;
      height: 25px !important;
      border: 1px solid transparent;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;

      &:hover {
        border-color: rgba(#241822,0.1);
      }
    }

    .swatch-label {
      width: 100% !important;
      height: 100% !important;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      img {
        position: static;
        border-radius: 50%;
      }
    }
  }

  /* .block-layered-nav {
    display: none;
  } */
}

.category-products-grid {
  width: calc(100% + 20px);
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: calc(25% - 20px);
    margin: 0 10px 50px;
    box-sizing: border-box;
    padding: 0;

    @include b1023 {
      width: calc(33.333% - 20px);
    }

    @include b767 {
      width: calc(50% - 20px);
    }
    
    .item-inner {
      padding: 0;
    }

    .product-image-wrapper {
      // padding: 4px;
      // border: 1px solid transparent;
      transition: border-color 0.3s;
      position: relative;

      .link-wishlist {
        .sic {
          width: 20px;
          height: 20px;
        }
      }
    }

    .addto-onimage {
      margin-top: -30px;
    }

    .sticker-wrapper {
      top: 24px;

      @include cleaner;

      &.top-left {
        left: 24px;

        @include b400 {
          left: 0;
        }
      }

      &.top-right {
        right: 24px;

        @include b400 {
          right: 0;
        }
      }
    }

    .name-price-wrap {
      display: flex;
      flex-direction: column;
    }

    .product-name {
      flex-grow: 1;
      padding: 0 4px;
      font-size: 14px;
      font-weight: normal;
      // text-transform: uppercase;
      letter-spacing: 0.7px;
      text-align: left;
      color: $b;
      margin: 32px 0 8px;
    }

    .price-box {
      padding: 0 4px;
      margin: 0 0 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      line-height: 22px;

      @include b639 {
        flex-direction: column;
      }

      .regular-price,
      .special-price {
        font-weight: bold;
        margin-right: 10px;

        .price {
          font-size: 18px;
          letter-spacing: 0.5px;
          color: $b;

          .price-currency {
            font-weight: bold;
          }
        }
      }

      .old-price {
        flex-grow: 1;

        .price {
          font-size: 14px;
          letter-spacing: 0.5px;
          color: rgba($b, 0.4);

          .price-currency {
            // font-weight: bold;
          }
        }
      }

      .new-price {
        // order: -1;
        display: flex;
        border: 1px solid $new;
        margin-right: 10px;

        @include b639 {
          order: -1;
          margin-bottom: 3px;
        }

        .discount-label {
          background-color: $new;
          color: #fff;
          padding: 0 4px;

          &:before {
            content: '-';
          }
        }

        .price {
          padding: 0 4px;
        }

        + .special-price {
          text-decoration: line-through;
        }
      }
    }

    .actions {
      display: none;
    }

    .add-to-links {
      li {
        button {
          background: none;
          padding: 0;
          cursor: pointer;

          &:before {
            font-size: 40px;
            color: #515050;
          }

          &:hover {
            &:before {
              color: #2e2e2e;
            }
          }
        }
      }
    }

    &:hover {
      .product-image-wrapper {
        border-color: #d01f45;
      }

      .sticker {
        color: $w;

        &.bestseller {
          background-color: $bestseller;
        }

        &.new {
          background-color: $new;
          border-color: $new;
        }

        &.sale {
          background-color: $sale;
        }
      }
    }
  }
}

.filter-block {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;

  .filter-close {
    width: 60px;
    height: 60px;
    background-color: #f4f4f4;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include b479 {
      width: 40px;
      height: 40px;
    }

    &:hover {
      color: $brown;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .current-filters {
    background: #f4f4f4;

    .btn-clear {
      cursor: pointer;
    }

    .block-subtitle {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      text-align: left;
      color: $b;
      padding: 20px 25px 15px;
      font-family: $font2;
    }

    .currently {
      padding: 0 25px;

      .multiselect-child {
        display: inline-block;
        padding: 0 25px 0 0;
        border-radius: 3px;
        // border: solid 1px #ca1e73;
        font-size: 16px;
        line-height: 20px;
        color: #98867a;
        margin-bottom: 8px;
        position: relative;

        .btn-remove {
          margin: 0;
          position: absolute;
          top: 1px;
          right: 4px;
          color: #98867a;
          cursor: pointer;

          &:hover {
            color: #000;
          }
          
          &:before {
            font-size: 14px;
            color: currentColor;
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .actions {
      padding: 15px 25px 25px;

      a {
        font-size: 14px;
        font-weight: 900;
        font-family: $font2;
        color: $b;
      }
    }
  }

  #narrow-by-list {
    margin: 0;

    dt {
      font-size: 14px;
      font-family: $font2;
      font-weight: 500;
      text-transform: uppercase;
      color: $b;
      padding: 20px 0;
      border-bottom: none;

      &:after {
        display: none;
      }
    }

    dd {
      margin-bottom: 30px;
      padding: 0 5px;
      
      li {
        padding: 4px 0;
      }
    }

    .layerednavigation-slider {
      width: calc(100% - 10px);

      .handle {
        width: 8px;
        height: 8px;
        background: $b;
        border: none;
        border-radius: 50%;
        margin-top: -4px;

        @include b479 {
          width: 20px;
          height: 20px;
          margin-top: -10px;
        }
      }

      &:before {
        background-color: rgba(45,45,45,0.1);
      }

      .layerednavigation-slider-bar {
        // height: 1px;
        background: $b;
      }
    }

    .layerednavigation-slider-price {
      display: none;
    }

    .price-range-btn-wrap {
      display: none;
    }

    .inpts-wrap {
      // width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price-range-from,
      .price-range-to {
        width: 60px;
        height: 24px;
        
        input {
          display: block;
          width: 100%;
          height: 100%;
          background: none;
          // border-radius: 4px;
          border: 1px solid rgba($b,0.2) !important;
        }
      }

      .f-to {
        font-size: 14px;
        color: $b;
        flex-grow: 1;
        text-align: right;
        padding-right: 10px;
        width: auto;

        &:first-child {
          flex-grow: 0;
        }
      }
    }


    .configurable-swatch-list {
      li {
        float: left;
        margin: 0 -1px 0 0;
        padding: 0;

        a {
          margin: 0;
          border: 1px solid rgba(180, 180, 180, 0.3);
          width: 50px;
          height: 50px !important;
          padding: 0;
          margin: 0;

          .count {
            display: none;
          }

          .swatch-label {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100% !important;
            font-size: 16px;
            color: $b;
            background: #fff;
            margin: 0;
            padding: 0;
          }
        }

        &.selected,
        &:hover {
          > a {
            .swatch-label {
              background: rgba(180, 180, 180, 0.3);
            }
          }
        }
      }
    }

    .img_wr {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      display: block;
      cursor: pointer;
    }

    .apply-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      border-radius: 3px;
      background: #d01f45;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.1px;
      color: #ffffff;
      text-transform: uppercase;
      border: 2px solid #d01f45;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: #fff;
        color: #d01f45;
      }

      display: none;
    }
  }

  .filter-block-inner {
    margin: 0;
    padding: 120px 100px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    max-width: 500px;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s;

    @include b479 {
      padding: 30px 30px;
    }

    .block-content {
      padding: 10px 0;

      .button {
        width: 100%;
        text-transform: uppercase;
        font-size: 16px;

        &.otstup {
          // margin: 20px 0;

        }
      }
    }

    //SCROLL SHANGE
    &::-webkit-scrollbar {
      width: 4px;

      &:horizontal {
        height: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #515050;
      border-radius: 4px;

      &:hover {
        background-color: $main-color;
      }
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .filter-block-inner {
      transform: translateX(0);
    }
  }

  div.block-layered-nav div.layerednavigation-slider div.handle {
    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      right: -9px;
      top: -3px;
      border-top: 9px solid transparent;
      border-left: 6px solid #ff9631;
      border-bottom: 9px solid transparent;
    }

    &:last-child {
      &:after {
        right: auto;
        left: -9px;
        border-left: none;
        border-right: 6px solid #ff9631;
      }
    }
  }

  .block-layered-nav .block-content > dl > dt {
    overflow: hidden;
    font-size: 1rem;

    span {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 1000%;
        top: 50%;
        left: 100%;
        background: $border-color-light;
        margin-left: 14px;
      }
    }
  }

  .block-title {
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 1.1px;
    color: $b;
    text-transform: uppercase;
    display: block;

    &:after {
      visibility: hidden;
    }
  }

  .block-subtitle--filter {
    display: none;
  }
}

.toolbar-bottom {
  position: relative;
  padding: 0;
  margin: 0;

  .toolbar {
    position: relative;
    top: 0;
    left: 0;
    right: unset;
    bottom: unset;

    .pager {
      padding: 11px 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -50vw;
    background: #f4f4f4;
  }

  .toolbar {
    margin: 0;

    .filter-opener {
      display: none;
    }
  }
}

.pager {
  padding: 0;

  .pages {
    > strong {
      display: none;
    }

    li {
      border: none;
      width: 38px;
      height: 38px;
      margin: 0;
      border-radius: 2px;
      font-size: 16px;
      color: #000;
      @include trs(border-color .3s ease);

      a {
        width: 38px;
        height: 38px;
        font-size: 16px;
        color: #000;
      }

      &:hover,
      &.current {
        background: $b;
        color: #fff;

        a {
          background: none;
          color: #fff;
        }
      }

      &.next {
        float: right;
      }

      &.previous {
        float: left;
      }

      &.next,
      &.previous {
        width: auto;

        a {
          font-size: 0;

          &:hover {
            background: none;
          }
        }

        span {
          display: none;
        }

        i {
          width: 38px;
          height: 38px;
          line-height: 38px;
          font-size: 30px;
          display: block;
          //transform: rotate(90deg);

          &:before {
            content: '\e912';
          }
        }
      }
    }
  }
}

.note-msg.empty-catalog {
  display: flex;
  align-items: center;
  //justify-content: center;

  &:before {
    background-size: contain;

    @include bp(max-width, 560px) {
      width: 80px;
      height: 80px;
      min-width: 80px;
    }
  }
}

.img-desc-mode {
  @media (max-width: 1366px) {
    display: none;
  }
}

.img-pad-mode {
  display: none;
  @media (min-width: 770px) {
    @media (max-width: 1365px) {
      display: block;
    }
  }
}

.img-mob-mode {
  display: none;
  @media (max-width: 769px){
    display: block;
  }
}

.category-description-container-seotext {
  clear: both;
  margin-bottom: 70px;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.5px;
  color: $b;

  .category-description {
    // max-height: 210px;
    // overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;

      &:horizontal {
        height: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8;
      border-radius: 4px;

      &:hover {
        background-color: transparent;
      }
    }

    div {
      *:last-child {
        margin: 0;
      }
    }
  }

  .read-more {
    font-size: 14px;
    letter-spacing: 0.6px;
    // color: #d51e34;
    margin-top: 30px;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &:after {
      content: '\e912';
      font-family: 'mysecret';
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      position: absolute;
      top: 1px;
      right: -30px;
      transition: transform 0.3s;
    }

    &.open {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}

.link-wishlist {

  &:hover {
    svg {
      opacity: 1;
    }
  }
  svg {
    opacity: 0.2;
  }
}

.sticker-discount {
  position: absolute;
  top: 0;
  background-color: rgba($w,0.8);
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}