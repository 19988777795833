@mixin bp($feature, $value) {
  // Set global device param
  $media: only screen;

  @media #{$media} and ($feature: $value) {
	@content;
  }
}

@mixin trs($transition...) {
  -webkit-transition: $transition;
  transition:         $transition;
}

@mixin mq($mq) {
  @if $mq == retina {
    @media
    (-webkit-min-device-pixel-ratio: 1.5),
    (min-resolution: 120dpi) {
      @content;
    }
  } @else {
    @media (max-width: $mqpx) { @content; }
  }
}

@mixin cleaner {
  &:after {
	content: '';
    display: block;
	clear: both;
  }
}

@mixin bContainer {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 1440px) {
    @content;
  }
}

@mixin b1399 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 1399px) {
    @content;
  }
}

@mixin b1199 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 1199px) {
    @content;
  }
}

@mixin b1099 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 1099px) {
    @content;
  }
}
@mixin b1023 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 1023px) {
    @content;
  }
}

@mixin b959 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 959px) {
    @content;
  }
}

@mixin b767 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 767px) {
    @content;
  }
}

@mixin b639 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 639px) {
    @content;
  }
}

@mixin b479 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 479px) {
    @content;
  }
}

@mixin b400 {
  // Set global device param
  $media: only screen;

  @media #{$media} and (max-width: 400px) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

%checkbox {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60' height='60'><path fill='#fff' d='M40.563 17.5l-11.525 17.605-11.3-7.395-2.737 4.18 15.483 10.137 14.262-21.787z'></path></svg>");
  background-color: $b;
  border-color: $b;
  color: #fff;
}