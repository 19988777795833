.cart {
  .product-image {
    border: 1px solid #e4e0e5;
    overflow: hidden;
    @include trs(border-color .3s ease);

    &:hover {
      border-color: $main-color;
    }
  }

  .link-edit {
    white-space: nowrap;
    color: #000;
    font-weight: bold;

    &:hover {
      color: $main-color;
    }

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    i {
      font-size: 1.125rem;
    }

    span {
      margin-left: 5px;

      @include bp(max-width, 991px) {
        display: none;
      }

      @include bp(max-width, $bp-tiny) {
        display: inline-block;
      }
    }
  }

  .btns-set {
    @include cleaner;
    padding: 35px 0;

    .checkout-types {
      @include cleaner;

      li {
        margin-bottom: 10px;

        a {
          font-style: italic;
          color: $main-color;

          &:hover {
            color: $dark;
          }
        }
      }

      @include bp(max-width, $bp-smallest) {
        float: none;

        button {
          width: 100%;
        }
      }
    }
  }

  .totals {
    .button {
      min-width: 210px;
      min-height: 49px;
    }

    .btn-continue {
      @extend %button;
      font-size: 14px;
      //min-width: 230px;
    }
  }
}

#shopping-cart-totals-table {
  width: 100%;
  font-size: 1rem;

  td {
    padding: 12px 0;
  }

  tfoot {
    td {
      vertical-align: bottom;
    }

    .price {
      font-size: 21px;
    }
  }
}

.btn-remove-round {
  font-size: 1.5rem;
  line-height: 1.75;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #c4bec5;
  border-radius: 50%;
  color: #c4bec5;
  @include trs(color .3s ease, border-color .3s ease);


  &:hover {
    color: $main-color;
    border-color: $main-color;
  }
}

.cart-table-wrapper {
  padding: 30px;
  background: #fafafa;

  .price {
    font-size: 1.3125rem;
    font-weight: bold;
    color: $main-color;
  }
}

%btn-update {
  border: none;
  background: none;
  font-size: 1rem;
  color: $gray;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  @include trs(color .3s ease);

  &:before {
    position: absolute;
    font-family: BaseIcons;
    content: '\e210';
    left: 0;
    top: 0;
    @include trs(transform .3s ease)
  }

  &:hover {
    color: $main-color;

    &:before {
      transform: rotate(180deg);
    }
  }
}

.cart-form-heading {
  @include cleaner;
  margin-bottom: 20px;

  .page-title {
    float: left;
    margin-bottom: 0;
  }

  .btn-update {
    @extend %btn-update;
    float: right;
    margin-top: 16px;
  }
}

//Discount

#discount-coupon-form {
  margin-bottom: 30px;
}

.discount {
  h2 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .discount-form {
    > label {
      margin-bottom: 15px;
      color: #8c838e;
    }
  }

  .input-box {
    white-space: nowrap;
    font-size: 0;
    position: relative;
    // padding-bottom: 20px;
    max-width: 440px;

    > *:not(.validation-advice) {
      display: inline-block;
      vertical-align: top;
      max-height: 38px;
    }

    .input-text {
      width: calc(100% - 145px);
      border-color: #a399a5;
    }

    .btn-use {
      min-width: 145px;
      text-transform: none;
      color: #47344c;
      border: 1px solid #c7c2c9;
      border-left: none;
      border-radius: 0 3px 3px 0;
      background: #fff;
      font-size: 14px;
      padding: 11px;
      cursor: pointer;
      @include trs(all .3s ease);

      &:hover {
        color: #fff;
        background: #000;
        border-color: #000;
      }
    }

    .validation-advice {
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}