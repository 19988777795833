// BREAKPOINTS
$bp-medium  : 1365px;
$bp-small   : 1023px;
$bp-desktop : 991px;
$bp-tiny    : 767px;
$bp-smallest: 479px;
$bp-micro   : 320px;

// FONT-WEIGHTS
$bold        : 700;
$medium      : 500;
$regular     : 400;
$light       : 300;

// LINEHEIGHTS
$lh-large       : 1.618;
$lh-medium      : 1.375;
$lh-small       : 1.2;
$lh-smallest    : 1;

//COLORS
$link :        #ccc;
$main-color:   #333;
$dark:         #5b4e5e;
$darkest:      #3e3341;
$gray:         #918594;
$border-color: #5c4c54;
$border-color-light: #d7d1d1;
$light:         #fafafa;
$white:        #ffffff;

$w:			#fff;
$b:			#2D2D2D;
$green:		#004F0E;
$brown:		#98867A;
$gray:		rgba(#2D2D2D,0.5);
$lgray:		rgba(#2D2D2D,0.2);
$llgray:	rgba(#2D2D2D,0.07);

$font:		'OpenSans';
$font2:		'Raleway';


$bestseller:		#ff0808;
$bestseller_after:	#ff0808;

$new:				#00510e;
$new_after:			#00510e;

$sale:				#C1723C;
$sale_after:		#C1723C;

