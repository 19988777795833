.footer-primary-container {
  padding-top: 65px;
  padding-bottom: 65px;
}

.footer-container {
  background-color: $b;
  color: $w;

  a {
    &:hover {
      color: $brown;
    }
  }


  .footer {
    h6,
    .h6 {
      font-family: $font2;
      font-weight: 900;
      margin-bottom: 16px;

    }

    .heading {
      font-size: 14px;
      cursor: default;

      @include b767 {
        margin-bottom: 0;
      }
    }
  }

  .block-footer-contacts {
    margin-bottom: 10px;
    font-size: 16px;
    color: #fff;
    line-height: 2;

    .content {
      overflow: hidden;
    }

    .operator-icon {
      float: left;
      margin: 0 10px 0 0;
    }

    .phone {
      font-size: 16px;
    }
  }

  .footer-primary {
    a {
      line-height: normal;
      font-size: 14px;

      &.callback {
        // text-transform: none;
        // color: #fff;
      }
    }

    .block-content {

        > div {
            // margin: 10px 0;
            padding: 5px 0;
        }

        .social-links {
          margin: -23px 0 0 -23px;

          @include b767 {
            justify-content: center;
          }
        }
    }

    // .block-title {
    //     display: none;
    // }

    @include bp(max-width, 980px){
        .inner-container {
            padding: 0;

            .footer-about {
                white-space: nowrap;

                .block-footer-contacts {
                    .content {
                        overflow: visible;
                    }
                }
            }
        }
    }
  }

  .footer-copyright {
    font-size: 1em;
  }

  .developed-by {
    .ic-brander {
      margin-left: 5px;
      font-size: 12px;
    }

    @include bp(max-width, $bp-tiny) {
      text-align: center;
    }
  }

  .footer-logo {
    display: block;
    margin-bottom: 35px;

    svg {
      max-width: 130px;
      width: 100%;
    }
  }

  .footer-contacts-holder {
    margin-bottom: 30px;
  }

  .callback-footer {
    &.button {
      padding: 0 10px;

      &:hover {
        color: $b;
      }
    }
  }

  @include bp(max-width, $bp-tiny) {
    text-align: center;

    .collapsible {
      margin: 0 -30px;
    }

    .block-title {
      padding: 40px 0 30px;
      margin: 0;

      >span {
        margin-right: 5px;
      }

      &.active {
        background: lighten($b, 10%);

        & + .block-content {
          background: lighten($b, 10%);
          padding-bottom: 30px;
        }
      }
    }

    .block-footer-contacts {
      .operator-icon {
        float: none;
      }

      .content {
        display: inline;
      }
    }

    .footer-logo {
      margin: 0 auto 40px;
    }

    .block-footer-contacts {
      font-size: 16px;
    }

    .callback-footer {
      margin-bottom: 50px;

      @include bp(max-width, $bp-smallest) {
        margin-bottom: 20px;
      }
    }

    .level-top {
      font-size: 16px;
      line-height: 2;
    }
  }

  .footer-catalog {
    box-sizing: border-box;

    @include bp(max-width, $bp-tiny) {
      .collapsible {
        margin: 0 -30px;
      }
    }
  }

  #subscribe-form {
    padding-top: 20px;

    label[for="newsletter"] {
      display: block;
      font-size: 14px;
      color: #fff;
      font-family: $font2;
      font-weight: 900;
      margin-right: 0;
    }

    .input-box {
      width: 100%;
      display: flex;
      float: none;
    }

    .input-text {
      // width: calc(100% - 45px);
      flex-grow: 1;
      height: 40px;
      background: none;
      border-width: 0 0 1px;
      border-color: $w;
      font-size: 12px;
      letter-spacing: 0.4px;
      font-weight: normal;
      color: $w;

      &:focus {
        box-shadow: none !important;
        border-color: #fff !important;
      }
    }

    .validation-failed {
        background: none !important;
        border-width: 0 0 1px;
    }

    .button {
      text-transform: none;
      font-size: 0;
      line-height: 1;
      width: 40px;
      height: 40px;
      margin-left: 8px;
      padding: 0;
      color: $b;

      &:hover {
        background-color: $brown;
        color: $w;
      }
      
      // &:before {
      //   content: '\e913';
      //   font-family: 'mysecret';
      //   font-size: 36px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   height: 36px;
      // }

      svg {
        stroke: currentColor;
        stroke-width: 1.5;
      }

      span {
        display: none;
      }
    }
  }

  .footer-social {
    @include bp(max-width, $bp-tiny) {
      .block-title {
        padding-bottom: 0;
      }
    }
  }

  .social-links {
    padding-top: 0;
    min-width: 275px;
    font-size: 0;
    // margin: 0 -5px;

    a {
      // font-size: .85rem;
      // display: inline-block;
      // vertical-align: top;
      // padding: 0 10px;
      // margin-bottom: 5px;

      // .ic {
      //   border-radius: 2px;
      //   border: 1px solid #747373;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   width: 44px;
      //   height: 44px;

      //   &:not(.ic-pinterest):before {
      //       font-size: 54px;
      //   }
      //   &.ic-pinterest:before {
      //       font-size: 28px;
      //   }
      // }
    }

    // @include bp(max-width, $bp-tiny) {
    //   margin-bottom: 30px;
    // }
  }
}

.footer-bottom {
    padding: 10px 0;

  .item-right {
    text-align: right;
  }

  .clearer {
    display: flex;
    justify-content: flex-end;

    @include b767 {
      justify-content: center;
    }

    @include b479 {
      flex-wrap: wrap;
    }

    // @include bp(max-width, 900px){
    //     display: block;

    //     .footer-copyright-block,
    //     .developed-by-block {
    //         text-align: center;
    //     }
    // }
  }

  
  .payment-icons-block {

  }
  
  .item {
    @include b767 {
      margin: 0;
    }

    @include b479 {
      width: 100%;
    }
  }
  // @include bp(max-width, $bp-tiny) {
  //   .item {
  //     float: none;
  //     width: 100%;
  //   }
  // }

  .footer-copyright {
    font-size: 9px;
    opacity: 0.5;
    height: 24px;
    line-height: 24px;
  }

  .developed-by {
    position: relative;
    height: 24px;
    line-height: 24px;
    font-size: 9px;
    opacity: 0.5;
    // text-transform: uppercase;
    padding-left: 10px;
    margin-left: 10px;

    @include b479 {
      margin-left: 0;
      padding-left: 0;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: calc(50% - 4px);
      width: 2px;
      height: 10px;
      background-color: rgba(#fff,0.5);

      @include b479 {
        content: none;
      }
    }

    .ic-brander {
        &:before {
            content: '\e620';
            font-family: 'BaseIcons' !important;
            font-size: 10px;
            position: relative;
            top: -1px;
        }
    }
  }

  .payment-icons {
    span {
        margin: 0 15px;
        height: 24px;
        display: inline-flex;

        img {
            opacity: 0.4;
        }
    }

    @include bp(max-width, $bp-tiny) {
        span {
            margin: 0 10px;
            height: 20px;
        }
    }
  }
}

.footer-primary-top {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  margin-right: -10px;

  @include b767 {
    flex-wrap: wrap;
  }

  .footer-about,
  .footer-catalog,
  .footer-info,
  .footer-social,
  {
    padding: 0 10px;

    @include b767 {
      width: 100%;
    }
  }

  .footer-about {
    .h6 {
      margin-bottom: 8px;
    }
  }
}

.footer-social {
  .developed-by {
    margin-top: 15px;

    .ic-brander:before {
        content: '\e620';
        font-family: 'BaseIcons' !important;
        font-size: 10px;
        position: relative;
        top: -1px;
    }
  }
}