// main styles

/* .products-grid {
  .price-box {
	display: table;
	width: 100%;
	min-height: 55px;
		margin: 10px 0 0 5px;

	.regular-price {
	  display: block;
	}
  }
	.actions{
		margin: 2px 0 0;
	}
} */

.social-links .ib,
.social-links a > span.icon {
  cursor: pointer;
}

.social-links {
	display: flex;
	flex-wrap: wrap;
	margin: -23px 0 0 -23px;

	noindex {
		display: flex;
		margin: 23px 0 0 23px;
	}
	a {
		width: 56px;
		height: 56px;
		border: 2px solid $w;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: #fff;
			color: $b;
		}

		.sic {
			width: 18px;
			height: 18px;
		}
	}
}

//Posts

.post-list {

  div.show-post {
	display: block;
	text-align: right;

	> span {
	  font-size: 1.28rem;
	  font-style: italic;
	}
  }
}

//Header
.header {
  .logo-wrapper {
	.logo {
	  display: block;
	}
  }

  .hp-block {
	@include bp(max-width, $bp-desktop) {
	  &.right-column {
		.item-left {
		  margin-right: 0;
		}
	  }

	  .header-contacts {
		.dropdown-heading,
		.dropdown-content li {
		  font-size: 1em;
		}
	  }
	}
  }
}


//Nav
@media only screen and (max-width: 1279px) and (min-width: 1024px) {
  .nav-regular .nav-item.level0 > a {
	padding: 0 12px;
  }
}

.nav-regular .classic > .nav-panel--dropdown li {
  position: relative;
}

.nav-regular .nav-item.level0.active > a {
  position: relative;

  &:after {
	position: absolute;
	content: '';
	width: 100%;
	height: 3px;
	bottom: 0;
	left: 0;
	background: $main-color;
  }
}

.nav-regular .simple > ul > li > a,
.nav-regular .nav-submenu--mega > li > ul > li {
  &:after {
	position: absolute;
	font-family: BaseIcons;
	content: "\e260";
	font-size: 1rem;
	font-weight: bold;
	line-height: 1;
	left: 5px;
	top: 50%;
	margin-top: -7px;
	transform: rotate(180deg);
  }
}

.nav-regular .nav-item.level0.first-regular {
  width: 25%;

  &.active {
	> a {
	  background: none;
	  color: #fff;
	}

	&:hover {
	  > a {
		background-color: #333333;
		color: #f2f2f2;
	  }
	}
  }
}

#nav-left {
  > li {
	> a {
	  font-size: 14px;
	  font-weight: bold;
	  padding-left: 32px;

	  @include bp(max-width, 1024px) {
		padding-left: 15px;
	  }

	  > .caret {
		float: right;
	  }
	}

	&:hover {
	  > a {
		color: #fff;
	  }
	}
  }

  ul.nav-submenu {
	padding: 0;

	li {
	  padding: 0;

	  a {
		padding: 12px 20px;
	  }
	}
  }
}

//Product page

#goto-reviews-form {
  font-weight: bold;
}

.product-view {
  > form {
	// background: #fff;
	// padding-top: 20px;
  }

  .qty-wrapper {
	label {
	  display: none;
	}
  }

  .fastbuy-btn {
	display: none;
  }

  .qty-wrapper {
	line-height: 55px;

	@include bp(max-width, 1199px) {
	  margin-bottom: 20px;
	}

	@include bp(max-width, 960px) {
	  float: none;
	}

	@include bp(max-width, $bp-tiny) {
	  margin-right: 20px;
	}

	@include bp(max-width, $bp-smallest) {
	  margin-right: 0;
	}

	> label {
	  display: none;
	}

	.ui-spinner {
	  width: 104px;
	  text-align: center;
	  border: none;
	  padding-right: 46px;
	  box-sizing: border-box;
	  height: 60px;

	  @include bp(max-width, $bp-desktop) {
		width: 195px;
	  }

	  &.ui-widget-content {
		background: $light;
	  }
	}

	#qty {
	  font-size: 30px;
	  height: auto;
	  border: none;
	  background: $light;
	  box-shadow: none;
	  margin: 0;
	  padding: 0 32px 0 0;

	  @include bp(max-width, 991px) {
		padding: 0;
	  }
	}

	.ui-spinner {
	  border-radius: 0;
	}

	.ui-spinner-button {
	  width: 45px;
	  height: 28px;
	  border: solid #fff !important;
	  background: $light;
	  border-radius: 0;
	  cursor: pointer;

	  &:last-child {
		border-bottom: none;
		height: 29px;
	  }

	  &.ui-spinner-up {
		border-width: 0 0 3px 3px !important;

		&:after {
		  content: '+';
		}
	  }

	  &.ui-spinner-down {
		border-width: 3px 0 0 3px !important;

		&:after {
		  content: '-';
		}
	  }

	  &:after {
		top: 0;
		font-size: 20px;
		position: absolute;
		left: 0;
		right: 0;
		line-height: 1.3;
		color: #a0a0a0;
		font-weight: bold;
	  }

	  &.ui-state-hover {
		background: darken($light, 10%);
	  }
	}

	.ui-state-default .ui-icon {
	  background: none;
	}
  }

  .ib.icon-color-productview, span.icon.icon-color-productview {
	background: none;
	font-size: 2em;
  }

  .add-to-box,
  .product-type-data > .price-box {
	border: none;
  }

  .add-to-box {
	padding: 0;
  }

  .gen-tabs {
	.tabs {
	  a {
		position: relative;
	  }
	}
  }

  .section-title {
	border: none;
  }
}

.ib-hover.icon-color-productview:hover,
.feature-icon-hover:hover .ib.icon-color-productview,
.feature-icon-hover:hover span.icon.icon-color-productview {
  background: none !important;
}

.product-view .product-shop .price-box .regular-price .price,
.product-view .product-shop .price-box .special-price .price,
.product-view .product-shop .price-box .special-price .price-label,
.product-view .product-shop .price-box .price-including-tax .price,
.product-view .product-options-bottom .price-box .regular-price .price,
.product-view .price-as-configured .price,
.map-popup-price .regular-price .price,
.product-view .price-box.map-info .old-price .price {
  //font-size: 2rem;
}

.product-view .product-shop .price-box .old-price .price {
  //font-size: 1.143em;
}

//end product page

// Footer

.footer-primary-top-spacing {
  margin-bottom: 40px;

  .footer-logo {
	display: block;
	max-width: 124px;
  }
}

.footer-primary-bottom {
  @include bp(max-width, $bp-desktop) {
	.item {
	  float: none;
	  margin: 0 0 30px;
	  text-align: center;

	  &:last-child {
		margin-bottom: 0;
	  }

	  #subscribe-form {
		max-width: 570px;
		margin: 0 auto;
	  }
	}
  }
}

.footer-bottom {
  .item-left {
	.footer-copyright {
	  text-transform: uppercase;
	}
  }

  .item-right {
	.footer-copyright {
	  > a {
		line-height: 1.2;
	  }
	}
  }

  .footer-copyright {
	.icon-brander {
	  &:before {
		font-size: 10px;
	  }
	}
  }
}


//404

.cms-index-noroute {
  .main {
	padding: 90px 0 115px;
	font-size: 14px;
	text-align: center;

	img {
	  margin-bottom: 60px;
	}

	.home-link {
	  font-size: 18px;
	  font-weight: bold;
	  color: $main-color;
	  padding-left: 20px;
	  position: relative;
	  display: inline-block;

	  &:hover {
		color: darken($main-color, 10%);
	  }

	  &:before {
		position: absolute;
		font-family: BaseIcons;
		content: "\e260";
		font-size: 26px;
		left: -10px;
		top: -5px;
		transform: rotate(90deg);
	  }
	}
  }
}