.social-list {
	li {
		display: inline-block;
		vertical-align: top;
	}
}

.icu {
	display: inline-block;

	&.ic-lg {
		font-size: 2em;
		line-height: 36px;
	}
}

%sic {
	width: 32px;
	height: 32px;
	fill:currentColor;
	display: inline-flex;
}
.sic {
	@extend %sic;
}

.sic10 {
	@extend %sic;
	width: 10px;
	height: 10px;
}

.sic16 {
	@extend %sic;
	width: 16px;
	height: 16px;
}

.sic22 {
	@extend %sic;
	width: 22px;
	height: 22px;
}

.sic24 {
	@extend %sic;
	width: 24px;
	height: 24px;
}

.sic26 {
	@extend %sic;
	width: 26px;
	height: 26px;
}

.sic34 {
	@extend %sic;
	width: 100%;
	height: 34px;
}

.sic45 {
	@extend %sic;
	width: auto;
	height: 45px;
}

.sic85 {
	@extend %sic;
	width: auto;
	height: 85px;
}

.sic110 {
	@extend %sic;
	width: auto;
	height: 110px;
}