.checkout-onepage-success {
	padding-bottom: 50px;
	
  .page-title {
	font-size: 48px;
	padding: 0 25px;

	&:before {
	  content: "";
	  display: inline-block;
	  vertical-align: top;
	  margin-top: -30px;
	  margin-right: -95px;
	  width: 126px;
	  height: 114px;
	  background: url("../images/thanks-bg.png") left top no-repeat;
	  background-size: 100%;
	}

	@include bp(max-width, $bp-small) {
	  margin-bottom: 0;
	  padding: 20px 0;
	  font-size: 30px;

	  &:before {
		width: 50px;
		height: 45px;
		margin: -12px -40px 0 -10px;
	  }
	}
  }

  .checkout-shopping-bag {
	float: none;
	width: auto;
	padding: 0;
	border: 0;

	.bag-title {
	  margin: 30px 0;
	  text-align: center;
	  font-size: 32px;
	}

	.checkout-item {
		width: 300px;
		margin: 0 auto 30px;
		text-align: center;

		.product-image {
			margin-bottom: 15px;
		}

		.item-options {
			li {
				width: 50%;
				float: left;
				padding: 0 5px;
				box-sizing: border-box;
				text-align: left;

				&.option-label {
					text-align: right;
				}
			}
		}

		.product-info {
			margin-bottom: 15px;
			@include cleaner;
			
			.obj {
				display: block;
			}
		}

		.price-col {
			//
		}
	}
  }

  .actions-block {
  	display: flex;
  	width: 100%;
  	justify-content: center;
  	align-items: center;

  	> .link-print {
  		padding-right: 10px;
  	}

  	> .text {
  		padding: 0 10px;
  		border-left: 1px solid #2d2d2d;
  	}

  	a {
  		font-size: 18px;
  		display: inline-block;

  		&:hover {
  			color: #515050;
  		}
  	}
  }

  @include bp(max-width, $bp-tiny){
  	.checkout-shopping-bag .bag-title {
  	    font-size: 21px;
  	}
  }

	.totals-wrap {
		line-height: 1.2;
		font-weight: 700;
		padding-top: 20px;
		margin: 0 auto;
		border-top: 1px solid #ccd;
		max-width: 500px;

		.totals-item {
			font-size: 1.1rem;
			margin-bottom: 14px;
			@include cleaner;

			&:last-child {
				font-size: 1.6rem;
				margin-bottom: 0;
			}
		}

		.totals-label {
			float: left;
			margin-right: 10px;
		}

		.totals-price {
			float: right;
		}
	}
}

.success-top {
  margin-bottom: 50px;
  text-align: center;

  .notes-wrap {
	margin: 0 auto 35px;
	line-height: 1.2;
  }

  .note {
	font-size: 24px;

	strong {
	  margin-left: 5px;
	}
  }

  .order-num-wrap {
	font-size: 16px;
	font-weight: $medium;
  }

  .order-num {
	font-size: 48px;
	line-height: $lh-smallest;
  }

  @include bp(max-width, $bp-small) {
	padding: 0 0 40px;

	.notes-wrap {
	  max-width: 290px;
	}

	.note {
	  font-size: 18px;
	}

	.order-num {
	  font-size: 36px;
	}
  }
}

.success-bottom {
  max-width: 460px;
  padding-bottom: 120px;
  margin: 0 auto;

  .comment-title {
	display: block;
	font-size: 1rem;
	font-weight: 600;
  }

  .products-list {
	margin: 0 -10px 20px;
	@include cleaner;
  }

  .item-inner {
	padding: 10px;
  }

  .product-image {
	text-align: center;
	margin-bottom: 10px;
	max-width: 100px;
  }

  .order-product-item {
	float: left;
	width: 31.33333%;
	padding: 10px;
	margin: 1%;
	box-sizing: border-box;
	border: 1px solid #ccd;

	@include bp(max-width, 640px) {
	  width: 50%;
	}

	@include bp(max-width, $bp-smallest) {
	  width: 100%;
	}

	.product-name {
	  font-size: 1rem;
	  font-weight: 600;
	  text-align: center;
	  display: block;
	}

	.obj {
	  display: block;
	  margin-bottom: 10px;
	}

	.price-col {
	  font-weight: 600;
	}
  }
}

.about-order {
  font-size: 16px;
  margin: 0 auto 60px;
  display: block;
  max-width: 500px;

  .title-wrap {
	margin-bottom: 20px;
  }

  .title {
	display: inline-block;
	margin-right: 30px;
	font-size: 32px;
  }

  .print-link {
	display: none;
	line-height: $lh-smallest;

	.text {
	  display: inline-block;
	  border-bottom: 1px dashed;
	}
  }

  .no-touch & {
	.print-link {
	  display: inline-block;
	}
  }

  .about-line {
	margin-bottom: 10px;

	.about-label {
	  display: inline-block;
	  vertical-align: middle;
	  min-width: 170px;
	  padding-right: 20px;
	}

	.about-val {
	  display: inline-block;
	  vertical-align: middle;
	}
  }

  @include bp(max-width, $bp-small) {
	font-size: 14px;

	.title {
	  margin-right: 20px;
	  font-size: 21px;
	}

	.print-link {
	  display: none;
	}

	.about-line {
	  font-size: 0;

	  .about-label {
		min-width: 0;
		width: 40%;
		font-size: 14px;
	  }

	  .about-val {
		width: 60%;
		font-size: 14px;
	  }
	}
  }
}