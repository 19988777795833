.header-container {
  .nav-regular {
    .nav-item {
      a {
        font-family: $font2;
        line-height: normal;
        font-weight: 500;
      }
    }
    > .nav-item.first {
      position: relative;

      @include bp(min-width, 800px) {
        box-sizing: border-box;

        > a {
          padding-left: 65px;
          position: relative;

          > .caret {
            display: none;
          }

          @include bp(max-width, 1024px) {
            &:after {
              left: 15px;
            }
          }
        }

        > ul {
          width: 100%;
        }
      }
    }
  }

  .header-top-container {
    background-color: $b;
    color: $w;

    a {
      &:hover {
        color: #a08f84;
      }
    }

    .left-column {
      .nav-item {
        &:first-child {
          a {
            padding-left: 0;
          }
        }
        a {
          height: 32px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .mini-products-list {
    .item {
      position: relative;
    }
    .ic-cart {
      position: absolute;
      top: 34px;
      right: -8px;
      line-height: 26px !important;
      font-size: 30px !important;
    }

    .btn-remove {
      overflow: visible;
    }

    .product-name {
      margin-left: 70px;
    }
  }

  .skip-link {
    .count {
      // margin-left: 26px;
      margin-top: -30px;
      position: static;
      font-family: $font;

      @include bp(min-width, 770px){
        position: absolute;
        right: 0;
        top: -10px;
        margin: 0;
        font-size: 12px;
      }
    }
  }

  &.sticky-header {
    .dropdown {
      .skip-link {

        .count {
          line-height: 1;
          top: 0;
        }
      }
    }
  }

  #sticky-catalog-menu {
    
    > a.level-top {
      padding-left: 40px;
      text-transform: uppercase;
      font-size: 15px;
      height: 40px;
      display: flex;
      align-items: center;

      &:before {
        left: 10px;
        content: '';
        width: 17px;
        height: 12px;
        border-color: $b;
        border-style: solid;
        border-width: 1px 0;
        top: 50%;
        margin-top: -6px;
        box-sizing: border-box;
        background: linear-gradient(0deg, transparent 0px, transparent 4px, $b 4px, $b 5px, transparent 5px);
      }
    }
    &:hover {
      a:before {
        border-color: $b;
        background: linear-gradient(0deg, transparent 0px, transparent 4px, $b 4px, $b 5px, transparent 5px);
      }
    }
  }

  .lang-switcher {
    &.dropdown {
      &.open {
        color: $b;

        .dropdown-heading {
          color: $b;
          display: block;
        }
      }


      .dropdown-content {
        min-width: 100%;
        box-sizing: border-box;

        .flag {
          background-position: 0 3px;
        }

        .current {
          opacity: .5;
        }
      }
    }

    .flag {
      background-repeat: no-repeat;
      background-position: 0 10px;
    }
  }

  .header-contacts {
    margin-left: -12px;

    .dropdown-heading,
    > span {
      font-size: 18px;
      line-height: 36px;

      > span {
        padding-left: 12px;
      }
    }

    > span {
      padding: 0 10px;
    }

    .dropdown-content {
      min-width: 100%;
      box-sizing: border-box;
      padding-top: 2px;
      padding-bottom: 6px;

      li {
        margin-bottom: 8px;
        white-space: normal;
        clear: both;
        padding-left: 2px;
        //font-size: 1rem;
        font-size: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .operator-icon {
        display: none;
        float: left;
        margin: 0 8px 8px 0;
      }

      .content {
        display: block;
        overflow: hidden;
        // margin-bottom: 8px;
      }
    }

    a {
      &:hover {
        color: $brown;
      }
    }
  }

  .callback-header {
    margin-right: 0;
  }
}

.callback-header {
  li {
    a {
      padding: 0;
    }
    
  }
  .callback-title {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-family: $font2;
    font-weight: bold;

    &:hover {
      color: $brown;

    }
  }
}

.header-regular {
  #mini-cart-label {
    display: none;
  }

  .hp-blocks-holder {
    display: flex;
    align-items: center;
    height: 140px;

    .central-column {
      order: -1;
      display: flex;
      align-items: center;
      flex-basis: 45%;
      flex-grow: 1;
      box-sizing: border-box;
      flex-wrap: wrap;
      justify-content: space-between;

      &:after {
        content: '';
      }
    }

    .right-column {
      box-sizing: border-box;
      flex-basis: 45%;
      flex-grow: 1;

    }
  }
}

.links-wrapper-separators .links > li {
  > a {
    background: none;
  }
}

.user-menu {
  [id$="-wrapper-regular"] {
    float: right;

    @include bp(max-width, 769px) {
      float: none;
    }
  }

  .dropdown {
    .dropdown-heading {
      font-size: 1.125rem;
      max-height: none;

      .caret {
        display: none;
      }

      .ic {
        font-size: 1.5rem;

        &.ic-heart {
          font-size: 1.3rem;
        }
      }
    }
  }
}

.dropdown {
  &.is-not-empty {
    color: $brown;
    .dropdown-heading {
      span {
        //color: $main-color;
      }

      .count {
        // color: $b;
      }
    }

    &.open {
      .dropdown-heading {
        .count {
          // color: #333;
        }
      }
    }
  }


  .btn-remove {
    font-size: 1.6rem;
    margin-top: -1px;
    cursor: pointer;
  }
}

.mini-cart {
  .mini-cart-content {
    .actions {
      button {
        padding: 10px 5px;

        &:last-child {
          float: right;
          margin-right: 0;
        }
      }
    }

    .btn-edit {
      font-size: 0;

      &:before {
        font-size: 14px;
      }
    }
  }
}

.skip-link {
  .count {
    position: absolute;
    height: auto;
  }
}

[id=mini-cart-heading] {
  
}

.header-mobile {
  .skip-link {
    position: relative;
    float: left;
    width: 20%;
    text-align: center;
    cursor: pointer;
    height: 50px;
    background-color: $llgray;

    @include bp(max-width, $bp-tiny){
      font-size: 12px;
      box-sizing: border-box;
    }

    svg {
      margin-right: 5px;
      flex-shrink: 0;
    }

    span {
      display: flex;
      align-items: center;
      
      

      // &:after {
      //   content: '';
      //   display: inline-block;
      //   height: 100%;
      //   vertical-align: middle;
      //   margin-right: -0.25em;
      // }
    }

    .label {
      @include b639 {
        display: none;
      }
    }

    .icon {
      margin-right: 3px;
      font-size: 16px;
    }
    .caret {
      display: none;
    }
  }

  a.skip-link {
    text-decoration: none;
  }

  .header {
    width: 100%;

    > .inner-container {
      padding: 0;
    }
  }

  .header-top {
    .hp-block {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .callback-header {
      position: static;
      float: right;
      margin-top: 0;
    }
  }

  #search_mini_form {
    .input-text,
    .button {
      color: $white;
    }
  }

  .logo-wrapper--mobile {
    .logo {
      max-width: 200px;
      margin: 0 auto;
      padding: 15px 0;
    }
  }

  #header-nav {
    .nav {
      width: 100%;
    }
  }

  .user-menu {
    border: none;
  }

  .container_header_primary_left_1 {
    display: block;
  }

  @include bp(max-width, 769px) {
    .hp-block {
      .item-right {
        float: none;
        margin-left: 0;
        text-align: left;
      }
    }
  }
}

// SEARCH AUTOCOMPLETE
.search-wrapper {
  .reset-button {
    display: none;
  }

  #search_mini_form {
    @include cleaner;

    .input-text {
      font-size: 13px;
      border-width: 0 0 1px;
      border-radius: 0;
      background: none;
      @include trs(color .15s ease-in-out);

      /* @include bp(min-width, 1500px) {
        width: 244px;
      }

      @include bp(max-width, 800px) {
        width: 180px;
      }

      @include bp(max-width, 769px) {
        width: 100%;
      } */

      /* & + button {
        font-size: 20px;
        color: $main-color;
      } */
    }
  }

  .searchautocomplete-search {
    border: 1px solid #d5d5d5;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    padding: 10px;
    right: 0;
    text-align: center;

    .s_item {
      @include cleaner;
      @include trs(border-color .3s ease);
      margin-bottom: 20px;
      border-bottom: 1px solid #333;

      &:hover {
        border-color: $main-color;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }

    .s_icon {
      margin-bottom: 10px;
    }

    .s_details {
      padding-bottom: 10px;
      box-sizing: border-box;
    }

    .s_description {
      display: none;
    }

    a {
      display: inline-block;

      .s_item_name {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 10px;
        @include trs(color .3s ease);
      }

      &:hover {
        .s_item_name {
          color: $dark;
        }
      }
    }
  }
}

.search-autocomplete {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);

  @include bp(max-width, $bp-tiny) {
    right: 0;
  }

  .search-more {
    font-style: italic;
  }
}

#mini-account {
  .mini-account-content {
    padding: 0;
  }

  .caret {
    display: none;
  }
}

.sticky-header {
  .mini-account-content {
    .links {
      a {
        padding: 6px 12px;
      }
    }
  }
  
}

.mini-account {
  &.is-not-empty {
    color: $w;

    .sticky-header &,
    .header-mobile & {
      color: $b;
    }
  }



  &.open {
    color: $b;
  }
}

[id^="mini-account"] {
  > a {
    display: inline-block;
    vertical-align: middle;
    padding-right: 12px;
  }
}

.sticky-header {
  #search_autocomplete {
    display: none;
  }

  .icon {
    font-size: 36px;

    &.ic-user {
      font-size: 24px;
    }
  }

  .touch & {
    #header-nav {
      display: none;
    }
  }
}

.mini-cart {
  .mini-cart-content{
    .actions {
      display: flex;
      justify-content: space-between;
      button{
        height: 43px;
        line-height: 1;
        padding: 0 5px;
        width: 50%;
      }
    }

    .subtotal {
      color: $b;
    }
  }
}

.sticky-header {
  .mini-account-heading {
    .sic16 {
      width: 26px;
      height: 26px;
    }
  }
}

[id=mini-account-wrapper-regular] {

  .mini-account-heading {

    > span {
      height: 32px;
      display: flex;
      align-items: center;
      
      svg {
        margin-right: 8px;
      }
    }
  }
}

[id=mini-account-wrapper-regular-slip] {
  a {
    display: flex;
    align-items: center;
    height: 32px;

    svg {
      margin-right: 8px;
    }
  }
}

.logo--regular {
  text-align: center;

  svg {
    @include b1099 {
      width: 180px;
    }
  }
}

.logo--mobile {
  svg {
    max-width: 100%;
  }
}