html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $font;
  color: $b;
  font-size: 12px;
}


.ic-odnoklasniki,
.ic-odnoklassniki,
.ic-yandex,
.ic-moikrug,
.ic-mailru,
//.ic-vk,
.ic-pinterest,
.ic-twitter,
//.ic-facebook,
.ic-skype,
.ic-linkedin,
.ic-tumblr,
.ic-blogger,
.ic-flickr,
.ic-vimeo,
//.ic-youtube,
.ic-renren,
//.ic-instagram,
//.ic-googleplus,
.ic-google {
  display: inline-block;
  vertical-align: top;
  line-height: 36px!important;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'SocIcons' !important;
}

@font-face {
  font-family: 'OpenSans';
  src:  url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src:  url('../fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src:  url('../fonts/raleway-family-v3.0/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src:  url('../fonts/raleway-family-v3.0/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src:  url('../fonts/raleway-family-v3.0/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src:  url('../fonts/raleway-family-v3.0/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

h1 {
  font-family: $font2;
  font-size: 35px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: $b;
}

h2 {
  font-family: $font2;
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: $b;
}


input,
button,
select,
textarea {
  font-family: $font;
}

input.input-text:not(#search):not(#qty),
select,
textarea {
  // border-color: #a399a5;
  @include trs(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  &:focus {
    border-color: $main-color;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 150, 49, .6);
  }
}

textarea {
  resize: vertical;
  max-width: 100%;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  transition: opacity 0.3s;
  color: currentColor;

  &:hover {
    color: $brown;
  }
}

.container {
  max-width: 1440px;
}

.inner-container {
  padding-left: 100px;
  padding-right: 100px;

  @include b1199 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@include bp(max-width, $bp-tiny){
  .inner-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage-productslider .itemslider-wrapper,
  .homepage-unitopblog,
  .the-slideshow-wrapper,
  .homepage-hotcategories {
    margin-left: 0;
    margin-right: 0;
  }
}


[data-show-btn],
[data-show-hide-block] {
    opacity: 0;
    transition: opacity 0.2s;

    &[data-disabled] {
        display: none !important;
    }

    &[data-loaded] {
        opacity: 1;
    }
}

// [data-show-hide-block] {
//     overflow: hidden;
//     max-height: 175px;
//     transition: max-height 0.3s;
//     position: relative;
//     //padding-bottom: 20px;

//     &:not(.open){
//       &:after {
//         content: '';
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         width: 100%;
//         height: 0;
//         box-shadow: 0 0 30px 10px #ffffff;
//       }
//     }
// }

[data-show-btn] {
    cursor: pointer;
}

[class*="like_h"] {
  display: block;
  margin: 0 0 10px;
  font-family: 'Roboto';
}
.like_h2 {
  font-size: 1.6666em;
  line-height: 1.2em;
}

.like_h3 {
  font-size: 1.6666em;
}

#oneclickbuy_form .popup-heading,
.webforms-callback {
  .like_h2 {
    color: #42536a;
    text-align: center;
    font-family: 'Open Sans', arial;
    margin: 0;
    font-size: 36px;
    margin-bottom: 10px;
  }
}

.header-top {


  .account-block {

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

.dropdown {

  > .dropdown-heading {
    
    &.cover {
      > span {
        height: 32px;
        display: flex;
        align-items: center;

        .sticky-header & {
          height: 40px;
        }
      }
    }
  }
}

// .dropdown {
//   &.open {
//     > .dropdown-heading {
//       &.cover {
//         > span {
//           height: 32px;
//           display: flex;
//           align-items: center;
//         }
//       }
//     }
//   }
// }

.header-primary-container {
  min-height: 64px;

  // [class^="ic-"],
  // [class*=" ic-"] {
  //   font-size: 38px !important;
  //   color: #fff;
  // }

  // .inner-container {
  //   @include bp(max-width, 959px){
  //     padding: 0;
  //   }
  // }

  // .dropdown {
  //   &.open {
  //     [class^="ic-"],
  //     [class*=" ic-"] {
  //       color: #000;
  //     }
  //   }
  // }

  // .callback-header {
  //   a {
  //     color: #fff;

  //     &:hover {
  //       color: #d01f45;
  //     }
  //   }
  // }

  // .header-contacts {
  //   > a {
  //     color: #fff;
  //   }

  //   &.open {
  //     > a {
  //       color: #000;
  //     }
  //   }
  // }

  .hp-blocks-holder {
    .hp-block {
      &.central-column {
        // position: relative;
        // left: -18px;

        // @include bp(max-width, 1210px){
        //   padding-left: 10%;
        // }

        .container_header_primary_central_1 {
          &:not(#callback-main) {
            margin: -3px 10% 0 3%;

            @include bp(max-width, 1420px){
              margin: -3px 7% 0 3%;
            }

            @include bp(max-width, 1340px){
              margin: -3px 3% 0 1%;
            }

            @include bp(max-width, 1250px){
              margin: -3px 0 0;
            }
          }
        }
      }
    }
  }

  .right-column {
    > .item-right {
      float: none;
      width: 100%;

      .user-menu {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

        #search-wrapper-regular {
          max-width: 260px;
        }

        @include b959 {
          flex-wrap: wrap;

          #search-wrapper-regular {
            margin-right: 10px;
            // max-width: 260px;

            .header-mobile & {
              max-width: none;
            }
          }
        }

        @include bp(max-width, $bp-tiny){
          display: block;
        }
      }
    }

    #search-wrapper-regular {
      flex-grow: 1;
      margin-right: 25px;
      
      @include bp(max-width, $bp-tiny){
        margin-right: 0;
      }

      .search-wrapper {
        float: none;
      }

      .form-search {
        input {
          border: none;
          height: 33px;
          background: #fff;
          // border-radius: 21px;
          padding: 0 33px 0 0;
          font-size: 12px;
          color: $gray;
          border-bottom: 1px solid $lgray;

          &:focus {
            border-color: $b;
          }

          /* @mixin placeholder {
            opacity: 0.3;
          } */
        }

        .btn-search {
          width: 33px;
          height: 33px;
          top: 0;
          right: 0;
          color: $b;
          margin: 0;
          line-height: 1;
          
          &:hover {
            svg {
              color: $b;

            }
          }


          svg {
            color: $gray;
            display: inline;
          }

          &:before {
            content: none;
          }
        }
      }
    }
  }
}

.header-container {
  &.header-mobile {
    .header-primary-container {
      .right-column {
        > .item-right {
          .user-menu {
            display: block;
          }
        }
      }
    }
  }
}

#root-wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper {
  height: 100%;
}

//Sticky footer

.page {
  //display: table;
  width: 100%;
  min-height: 100vh;
  //table-layout: fixed;
  display: flex;
  flex-direction: column;

  .main-container {
    flex-grow: 1;
  }

  /* .header-container {
    display: table-header-group;
    height: 1%;
  } */

  /* .main-container {
    display: table-row;
    height: 1%;
  } */

  /* .footer-container {
    display: table-footer-group;
    height: 1%;
  } */
}

.customer-account,
.checkout-cart-index,
.customer-account-login,
.checkout-onepage-success
{
  .main-container {
    margin-top: 30px;
  }
}

.main {
  height: 100%;

  &.not-full-width {
    //border-left: 1px solid #f6f3ef;
    //border-right: 1px solid #f6f3ef;
  }
}

%button {
  min-width: 130px;
  border: 2px solid $b;
  color: $b;
  background: #fff;
  font-weight: normal;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  padding: 0 23px;
  @include trs(color .3s ease, background-color .3s ease, border-color .3s ease);
  cursor: pointer;

  &:hover {
    background: $b;
    color: $w;
  }

  &:focus {
    // color: $white;
    // background: darken($main-color, 20%);
    // border-color: darken($main-color, 20%);
    outline: 1px dotted $gray;
    outline-offset: -5px;
  }
}

%btn-gray {
  box-sizing: border-box;
  font-weight: normal;
  text-transform: none;
  color: #47344c;
  border-color: #c7c2c9;
  min-width: 198px;

  &:hover {
    color: $white;
    background: #c7c2c9;
  }

  @include bp(max-width, 991px) {
    min-width: 130px;
  }
}

.button {
  @extend %button;
}

a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.naked {
  @extend %button;
  border: none;
  background: none;
  font-family: $font2;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding-left: 0;
  padding-right: 0;
  min-width: 0;

  &:hover {
    background: none;
    color: $b;

    svg {
      color: $brown;
    }
  }

  &.bold {
    font-weight: 900;
    letter-spacing: 0.2px;

    &:hover {
      color: $brown;
    }
  }

  svg {
    stroke: currentColor;
    stroke-width: 5px;
    margin-right: 8px;
  }
}

.ondark {
  @extend %button;
  background-color: $b;
  border-color: $w;
  color: $w;

  &:hover {
    background-color: $w;
    color: $b;
  }

  &.naked {
    background: none;

    &:hover {
      color: $w;

      svg {
        color: $brown;
      }
    }

    &.bold {
      &:hover {
        color: $brown;
      }
    }
  }
}


.btn-search,
.btn-subscribe,
.btn-use {
  border: none;
  min-width: 0;
  color: inherit;
  border-radius: 0;
  font-weight: normal;
  letter-spacing: 0;

  &:hover {
    color: $dark;
  }
}

.btn-remove,
.btn-remove2,
.map-popup-close,
.remember-me-popup-head .remember-me-popup-close {
  color: $main-color;
  line-height: 1;
}

.toolbar {
  .sort-by {
    .category-asc,
    .category-desc {
      border: 1px solid $border-color-light;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: $main-color;
      text-align: center;
    }
  }

  .chosen-container {
    margin-right: 22px;

    @include bp(max-width, $bp-smallest) {
      margin-right: 10px;
    }
  }

  @include bp(max-width, $bp-tiny) {
    /* label {
      display: none;
    } */

    .sort-by,
    .limiter {
      float: none;
      margin-left: 0 !important;
      display: block;
      width: 100%;

      label {
        width: 130px;
        // float: left;
      }

      .chosen-single {
        border-bottom: 1px solid #515050 !important;
      }
    }

    .sort-by {
      // margin-bottom: 10px;
    }
  }

  @include bp(max-width, 480px) {
    .sort-by {
      label {
        // width: 100%;
      }

      select {
        padding-right: 20px;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='7'><path fill='none' stroke-width='1.5' stroke='#000' d='M1 1l5 4l5-4'></path></svg>");
        background-repeat: no-repeat;
        background-position: right center;
      }
    }

    .limiter {
      label {
        width: auto;
      }

      select {
        padding-right: 20px;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='7'><path fill='none' stroke-width='1.5' stroke='#000' d='M1 1l5 4l5-4'></path></svg>");
        background-repeat: no-repeat;
        background-position: right center;
      }
    }
  }

  .limiter {
    //display: none;
  }

  select {
    border-color: #515050;
    border-width: 0 0 1px;
    outline: none;
    box-shadow: none;
  }

  .view-mode {
    button{
      cursor: pointer;
    }
    .ic {
      font-size: 20px;
      color: #dbd0db;
      @include trs(color .3s ease);
      border: 0;
      background: none;
    }

    > label {
      display: none;
    }
  }
}

// blog pager
.breadcrumbs {
  // margin-bottom: 20px;
  line-height: 20px;
  padding: 16px 0 5px;

  @include b479 {
    padding: 10px 0 0;
  }

  li {
    font-size: 12px;
    // letter-spacing: 0.4px;
    color: $gray;
    // text-transform: uppercase;

    &:last-child {
      color: $b;
    }

    a, span {
      color: inherit;
    }

    .sep {
      font-size: 14px;
      margin: 0 3px 0 2px;
      font-weight: bold;
      top: -1px;
      position: relative;

      &:before {
        content: '/';
      }
    }
  }
}

.cmsadvanced-page-view {
  .breadcrumbs {
    line-height: 20px;
    padding: 20px 0;
    position: relative;
    margin-bottom: 35px;

    ul {
      position: relative;
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   width: 100vw;
    //   height: 100%;
    //   top: 0;
    //   left: 50%;
    //   margin-left: -50vw;
    //   background: #f4f4f4;
    // }
  }
}

.category-blog {
  .toolbar {
    border-top: 0;

    .pager {
      border-top: 0;
    }
  }
}

/* .breadcrumbs {
  li {
    .sep {
      vertical-align: top;
      margin: 0 12px 0 10px;

      &:before {
        content: '/';
      }
    }
  }
} */

#sh_button {
  @include bp(max-width, $bp-tiny) {
    min-height: 0;

    .sh_title_text {
      display: none;
    }
  }
}


.no-touch {
  .products-grid .item-inner:hover .product-image {
    border-color: $main-color;
  }
}

.touch {
  .control .checkbox {
    & + label {
      &:hover:after {
        opacity: 0;
        visibility: hidden;
      }
    }

    &:checked + label:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.ui-state-hover,
.ui-widget-content .ui-state-hover {
  border-color: #d3d3d3;
}

.benefits-container,
.homepage-benefits {
  margin-bottom: 20px;

  .benefits-grid {
    font-size: 0;
  }

  .benefit {
    > a {
      display: block;
      height: 100%;
      padding: 35px;
      transition: background-color .4s ease-out;
      box-sizing: border-box;

      &:hover {
        background: #ddd;
      }
    }
  }

  $class-name: "benefits-per-row";
  //@for $i from 1 through 10 {
  //.#{$class-name}-#{$i} .benefit {
  //  width: (100% / $i);
  //}
  //}

  .img-wrap {
    display: block;
    margin-bottom: 20px;
  }

  .benefit-title {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;

    b {
      font-weight: normal;
      line-height: 1.5;
    }
  }

  .benefit-text {
    display: block;
  }

  .product-view & {
    padding: 15px 50px;

    .img-wrap {
      margin-bottom: 15px;
    }

    .benefit-title {
      margin-bottom: 8px;
      font-size: 16px;
    }

    .benefit {
      > a {
        padding: 15px;
        font-size: 14px;
        line-height: $lh-small;
      }
    }
  }

  //@include bp(max-width, $bp-medium) {
  //  @for $i from 2 through 10 {
  //    .#{$class-name}-#{$i} .benefit {
  //      width: (100% / ($i - 1));
  //    }
  //  }
  //}

  //@include bp(max-width, $bp-small) {
  //  @for $i from 3 through 10 {
  //    .#{$class-name}-#{$i} .benefit {
  //      width: (100% / ($i - 2));
  //    }
  //  }
  //}

  @include bp(max-width, $bp-tiny) {

    .benefit {
      width: 100%;
      text-align: center;

      > a {
        padding: 15px;
      }
    }

    .benefit-title {
      font-size: 16px;
    }

    .benefit-text {
      font-size: 1rem;
      line-height: 1.3;
    }

    //@for $i from 2 through 10 {
    //  .#{$class-name}-#{$i} .benefit {
    //    width: (100% / ($i - 1));
    //  }
    //}
  }

  @include bp(max-width, $bp-smallest) {
    @for $i from 3 through 10 {
      .#{$class-name}-#{$i} .benefit {
        //width: (100% / ($i - 2));
        width: 50%;

        @include bp(max-width, $bp-tiny) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .benefit-title {
      font-size: 14px;
    }
  }

  @include bp(max-width, $bp-micro) {
    @for $i from 4 through 10 {
      .#{$class-name}-#{$i} .benefit {
        width: (100% / ($i - 3));
      }
    }
  }
}

.sticker-wrapper {
  .sticker {
    width: auto;
    height: auto;
  }

  &.top-right {
    top: 20px;
    right: 10px;
  }

  &.top-left {
    top: 20px;
    left: 10px;
  }
}

.sticker-wrapper {
  top: 10px;

  @include cleaner;

  &.top-left {
    left: 24px;
  }

  &.top-right {
    right: 24px;
  }

  .sticker {
    float: left;
    clear: left;
    margin-bottom: 6px;

    // background: #fff;
    border-width: 2px;
    border-style: solid;
    position: relative;
    // left: -7px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    min-width: 60px;
    text-align: center;
    letter-spacing: 1px;
    // padding: 8px 4px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border: 3px solid transparent;
    }

    

    &.bestseller {
      border-color: $bestseller;
      color: $bestseller;
    }
    &.new {
      border-color: $new;
      color: $new;
    }

    &.sale {
      border-color: $sale;
      color: $sale;

      &:before {
        content: '-';
      }
    }

    &.for-feeding {
      width: 37px;
      height: 39px;
      padding: 0;
      background: none;
      border: none;
      font-size: 0;
      background: url(../images/sticker/for_feeding.png) no-repeat 50% 50%;
      background-size: contain;
    }

    &.extra-sale {
      flex-direction: column;
      align-items: stretch;
      border-color: $new;
      height: auto;

      i {
        font-style: normal;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        background-color: $new;
        color: $w;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        padding-top: 2px;
        margin-bottom: -1px;
        padding-left: 1px;

      }
    }
  }
}

.the-slideshow-wrapper-outer {
  @include b959 {
    .container .inner-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.the-slideshow {
  .item {
    img {
      width: 100%;
    }
  }
}

.item-inner {
  position: relative;
  //background: #ffffff;
  padding: 20px 10px;
}

.section-title {
  font-size: 35px;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: $font2;
  padding-top: 20px;
  padding-bottom: 20px;

  &.padding-right {
    padding-right: 100px;
    padding-left: 100px;
    margin-bottom: 14px;
    text-align: center;
  }
}

//owl carousel

.slider-arrows1 .owl-controls {
  .owl-pagination {
    @include bp(min-width, 785px) {
      display: none;
    }
  }

  .owl-buttons {
    @include bp(max-width, 784px) {
      display: none;
    }

    // div {
    //   width: 40px;
    //   height: 40px;
    //   border: 1px solid $main-color;
    //   border-radius: 50%;
    //   background: $white;
    //   color: $main-color;
    //   margin-right: 10px;
    //   position: relative;
    //   @include trs(color .3s ease, background-color .3s ease);

    //   &:before {
    //     position: absolute;
    //     font-size: 33px;
    //     line-height: 1;
    //     left: 0;
    //     right: 0;
    //     top: 4px;
    //     transform: rotate(90deg);
    //   }

    //   &:hover {
    //     background-color: $main-color !important;
    //     color: $white !important;
    //   }

    //   &.owl-next {
    //     margin-right: 0;
    //   }
    // }
  }

  .owl-pagination {
    .owl-page {
      span {
        background-color: #9b9b9b;
      }

      &:hover span {
        background-color: #000;
      }

      &.active {
        &:hover span,
        span {
          background-color: #000;
        }
      }
    }
  }
}

.itemslider-num {
  display: none;
  font-size: 20px;
  text-align: center;
  padding: 10px 0;

  span {
    padding: 0 30px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-width: 120px;
    box-sizing: border-box;

    &:before,
    &:after {
      position: absolute;
      font-family: BaseIcons;
      content: '\e260';
      font-size: 1.5em;
      top: 50%;
      left: 0;
      margin-top: -18px;
      transform: rotate(90deg);
    }

    &:after {
      left: auto;
      right: 0;
      transform: rotate(-90deg);
    }
  }

  @include bp(min-width, 784px) {
    display: none;
  }
}

.itemslider {
  // margin-bottom: 70px;
  margin-left: -10px;
  margin-right: -10px;

  &.products-grid {
    .item {
      padding: 0 10px;

      // @include bp(max-width, $bp-tiny){
      //   .product-name,
      //   .price-box {
      //     text-align: center;
      //     justify-content: center;
      //   }
      // }

      &:hover {
        .sticker {
          color: $w;
        }

        .bestseller {
          background-color: $bestseller;
        }
        
        .new {
          background-color: $new;
        }

        .sale {
          background-color: $sale;
        }
      }



      .product-image-wrapper {
        // padding: 4px;
        // border: 1px solid transparent;
        // transition: border-color 0.3s;
        position: relative;
        margin-bottom: 20px;
      }

      .product-name {
        padding: 0 4px;
        font-size: 16px;
        letter-spacing: 0.7px;
        text-align: left;
        color: #000000;
        text-transform: uppercase;
        margin-bottom: 10px;

        a {
          &:hover {
            color: #d01f45;
          }
        }
      }

      .price-box {
        padding: 0 4px;
        margin: 0;
        display: flex;
        align-items: center;
        line-height: 22px;

        .regular-price,
        .special-price {
          margin-right: 10px;
          font-weight: bold;


          .price {
            font-size: 18px;
            letter-spacing: 0.5px;
            color: #000000;

            .price-currency {
              font-weight: bold;
            }
          }
        }

        .old-price {
          .price {
            font-size: 14px;
            letter-spacing: 0.5px;
            color: rgba($b, 0.4);

            .price-currency {
              // font-weight: bold;
            }
          }
        }
      }

      


    }
  }

  &.news-grid {
    margin-bottom: 0;

    .item {
      border-bottom: 1px solid #000;
      margin: 0 10px;
      box-sizing: border-box;
      padding: 0 0 20px;

      .product-image {
        display: block;
        margin-bottom: 20px;
      }

      .blog-name {
        font-size: 18px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #000000;
        margin-bottom: 10px;
      }

      .blog-desc {
        font-size: 16px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #000000;
      }
    }
  }

  &.rewiews-grid {
    position: relative;
    padding: 0 0 20px;
    margin-bottom: 0;

    .item-inner {
      background: none;
    }

    .item {
      height: auto !important;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 15px 10px 50px;

      .image-block {
        width: 155px;
        margin-right: 45px;
        min-height: 235px;
        display: flex;
        align-items: center;
      }

      .info-block {
        width: calc(100% - 200px);
        padding-right: 40px;
        box-sizing: border-box;

        .top-part {
          margin-bottom: 24px;
          display: flex;
          width: 100%;
          align-items: center;

          > div {
            padding: 0 10px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);

            &:first-child {
              padding-left: 0;
              border-left: none;
            }
          }

          .user-name {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.33;
            text-align: left;
            color: #000000;
          }

          .review-from,
          .date {
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #000000;
            opacity: 0.5;
          }
        }
        .description {
          font-size: 16px;
          line-height: 1.56;
          letter-spacing: 0.5px;
          text-align: left;
          color: #000000;
        }

        .all-button {
          font-size: 14px;
          letter-spacing: 0.6px;
          color: #d51e34;
          margin-right: 30px;
          display: inline-block;
          margin-top: 10px;
        }
      }
      
      .bottom-block {
        margin-top: 24px;
        width: 100%;

        .name {
          font-size: 16px;
          letter-spacing: 0.7px;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
        .price-block {
          font-size: 18px;
          //font-weight: bold;
          letter-spacing: 0.5px;
          text-align: left;
          color: #000000;

          .price-currency {
            font-weight: bold;
          }

          .old-price {
            font-size: 14px;
            color: rgba(0,0,0,0.5);
          }
        }
      }

      @include bp(max-width, 1200px){
        .image-block {
          width: 130px;
          margin-right: 20px;
        }

        .info-block {
          width: calc(100% - 150px);
        }
      }

      @include bp(max-width, 1100px){
        .info-block {
          .top-part {
            flex-wrap: wrap;

            .user-name {
              width: 100%;
              margin-bottom: 15px;
            }

            .review-from {
              padding-left: 0;
              border-left: none;
            }
          }
        }
      }

      @include bp(max-width, 640px){
        .image-block {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
          //text-align: center;
          max-height: 200px;

          img {
            max-height: 200px;
          }
        }

        .info-block {
          width: 100%;
          padding: 0;
        }
      }
    }

    .owl-controls {
      .owl-pagination {
        display: block;
      }
    }
  }
}

.block-viewed {
  .section-title {
    position: relative;
    // padding-top: 70px;
  }
}

.show-all-block {
  padding: 30px 0 70px;
  display: flex;
  justify-content: center;
  position: relative;

  .all-button {
    height: 50px;
    box-sizing: border-box;
    padding: 0 18px;
    border-radius: 2px;
    //background-color: rgba(135,135,135,0.09);
    border: solid 1px #000;
    font-size: 14px;
    letter-spacing: 0.7px;
    text-align: left;
    color: #d0021b;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: background-color 0.3s;

    &:after {
      content: '\e914';
      font-family: 'mysecret';
      display: inline;
      font-size: 20px;
      font-weight: bold;
      margin-left: 8px;
      margin-top: -2px;
      color: #000;
    }

    &:hover {
      background: rgba(135,135,135,0.3);
    }
  }
}

.gray-bg {
  position: relative;
  margin-bottom: 70px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    height: 100%;
    background: #eeeeee;
  }

  .show-all-block {
    border-top: 1px solid #000;
  }
}

.no-pagination {
  .owl-pagination {
    display: block;
    margin: 20px 0 10px;
  }
}

.itemslider-wrapper {
  //margin: 0 -10px;

  .owl-controls .owl-buttons {
    z-index: 1;
    top: -70px;
    right: 10px;
    // left: -45px;
    // width: calc(100% + 90px);
    // height: 100%;

    .owl-prev,
    .owl-next {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $b;
      margin: 0;
      padding: 0;
      font-size: 34px;
      background-color: $llgray;
      transform: rotate(0);

      &:hover {
        background-color: $b;
        color: $w;
      }

      &:before {
        content: '\e914' !important;
        font-family: 'mysecret' !important;
        position: relative;
        font-weight: bold;
        font-size: 22px;
      }
    }

    .owl-prev {
      margin-right: 3px;
      &:before {
        transform: rotate(180deg) translateY(-1px);
      }
    }
    .owl-next {
      &:before {
      }
    }
  }

  .owl-wrapper-outer {
    z-index: 2;
  }

  .owl-wrapper {
    display: flex !important;

    .owl-item {
      height: auto;

      .item {
        height: 100%;
      }
    }
  }

  .item-inner {
    padding: 0;
  }

  .ratings {
    display: none;
  }
}

.the-slideshow-wrapper {
  .slider-arrows2 {
    overflow: hidden;
  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}


#uni-main-loader-checkout,
#sub-loader {
  position: fixed;
  z-index: 999;
}

.layerednavigation-overlay,
#sub-loader {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &:after {
    position: fixed;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .4;
    z-index: 10000;
  }
}

.sk-circle {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10001;
  opacity: .85;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $main-color;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

//loader end

.chosen-container-single {
  .chosen-single {
    text-align: left;
    padding: 0 36px 0 10px;
    border-color: $border-color-light;

    div {
      width: 36px;
      height: 100%;
      top: 0;
      border-left: 1px solid $border-color-light;

      &:after {
        position: absolute;
        font-family: BaseIcons;
        content: "\e260";
        font-size: 20px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
      }

      b {
        display: none;
      }
    }
  }

  .chosen-results li,
  .chosen-drop {
    border-color: $border-color-light;
  }

  &.chosen-with-drop {
    .chosen-single {
      div {
        &:after {
          transform: rotate(180deg)
        }
      }
    }
  }
}

.sorter {
  .sort-by {
    .chosen-container {
      min-width: 212px;
    }
  }

  .amount {
    display: none;
  }
}

.limiter {
  .chosen-container {
    min-width: 76px;
  }
}

//Contacts
.contacts-wrap {
  @include cleaner;

  .buttons-set p.required {
    margin: 0 0 10px;
    float: none;
  }

  .contacts-info-holder,
  .contacts-form-holder {
    float: left;
    width: 50%;
    box-sizing: border-box;

    @include bp(max-width, 850px) {
      width: 100%;
      margin-bottom: 20px;
      float: none;
    }
  }

  .contacts-info-holder {
    padding-right: 30px;

    @include bp(max-width, 850px) {
      padding-right: 0;
    }
  }

  h2.legend,
  .feature .heading {
    font-family: 'PFSquareSansPro', Arial, sans-serif;
    font-weight: bold;
  }

  .contacts-map-holder {
    //float: right;
  }

  .feature,
  .phones {
    margin-bottom: 20px;
  }

  .field {
    margin-bottom: 8px;
  }

  .contacts-address {
    font-size: 1.4em;
    padding-top: 20px;
  }

  .form-list input.input-text,
  .field,
  #contactForm textarea {
    width: 100%;
    max-width: 100%;
    resize: vertical;
  }

  @include bp(max-width, 850px) {
    .buttons-set {
      .button {
        width: 100%;
      }
    }
  }

  .contacts-map-holder {
    //width: 500px;
    margin-bottom: 50px;

    @include bp(max-width, 850px) {
      /* width: 100%;
      margin: 0 auto;
      float: none; */

      #map {
        margin: 0 auto;
      }
    }
  }
}

//checkboxes
.input-box {
  .checkbox,
  .radio {
    opacity: 0;
    width: 27px;
    height: 27px;
    position: relative;
    cursor: pointer;
    z-index: 2;

    & + .label {
      position: relative;
      margin-left: 0;
      padding-left: 36px;
      cursor: pointer;
      z-index: 1;

      label {
        cursor: pointer;
      }

      &:after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        border: 1px solid #dadada;
        left: 0;
        top: 2px;
        border-radius: 3px;
      }

      &:before {
        display: block;
        border: none;
        position: absolute;
        font-family: BaseIcons;
        content: '\e607';
        line-height: 1;
        left: 6px;
        top: 8px;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        @include trs(opacity .3s ease, visibility .3s ease);
      }
    }

    &:hover,
    &:checked {
      & + .label {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.control {
  .checkbox {
    opacity: 0;
    display: none;

    & + label {
      position: relative;
      margin-left: 0;
      padding-left: 32px;
      padding-top: 4px;
      cursor: pointer;

      &:after {
        position: absolute;
        font-family: 'mysecret';
        content: '✓';
        font-size: 12px;
        width: 23px;
        height: 23px;
        line-height: 1.9;
        text-align: center;
        z-index: 1;
        color: #fff;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity .3s ease, visibility .3s ease;
        transition: opacity .3s ease, visibility .3s ease;
      }

      &:before {
        position: absolute;
        content: '';
        width: 21px;
        height: 21px;
        border: 1px solid #d7d1d1;
        top: 0;
        left: 0;
      }

    }

    & + label:hover,
    &:checked + label {
      &:after {
        opacity: 1;
        visibility: visible;
        background: #D0031B;
      }
    }
  }
}

.webforms-callback {
  .callbacks-welcome-message {
    margin-bottom: 20px;
  }

  .validation-advice {
    padding-left: 90px;

    @include bp(max-width, $bp-smallest) {
      padding-left: 0;
    }
  }
}

.fancybox-inner {
  height: auto !important;
}

@include bp(max-width, $bp-smallest) {
  .webforms-callback input.input-text {
    width: 100%;
  }

  .section-title.padding-right {
    padding-right: 0;
    padding-left: 0;
  }

  .itemgrid.itemgrid-adaptive .item {
    width: 98%;
  }
}

.fancybox-lock {
  #sh_button {
    right: 17px;
  }
}

.compare-table {
  .product-name {
    font-weight: bold;
  }

  td {
    vertical-align: top;
  }

  .link-wishlist {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .ic-heart {
      margin-right: 5px;
    }
  }
}

// .social-list {
//   font-size: 0;
//   margin: 0 -5px;

//   a {
//     font-size: .85rem;
//     display: inline-block;
//     vertical-align: top;
//     //padding: 0 5px;
//     margin-bottom: 5px;
//   }
// }

.data-table {
  .even {
    // background: #f4f4f4;
  }
}

.cart-table-wrapper {
  .data-table {
    .even {
      background: #f4f4f4;
    }
  }
}

#scroll-to-top {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  @include trs(color .3s ease);
  transform: rotate(180deg);

  &:before {
    font-size: 40px;
    text-shadow: 0 0 5px $b;
  }

  &:hover {
    background-color: $b;
    color: $w;
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

@media only screen and (max-width: 959px) and (min-width: 768px) {
  .data-table th, .data-table td {
    padding: 5px;
    font-size: 12px;
  }
}

.rating-box {
  width: 120px;
  height: 16px;

  @include mq(retina) {
    width: 100px;
    background-size: 20px 50px;
  }

  .rating {
    height: 16px;
    @include mq(retina) {
      background-size: 20px 50px;
    }
  }
}

//Brands

.brands-tabs {
  font-size: 0;
  margin-bottom: 40px;
  overflow: hidden;
  columns: 5;

  @include bp(max-width, $bp-tiny) {
    columns: 4;
  }

  @include bp(max-width, 640px) {
    columns: 3;
  }

  @include bp(max-width, $bp-smallest) {
    columns: 2;
  }

  .horizontal {
    @include cleaner;
    font-size: 1rem;
    margin-bottom: 40px;
    border-top: 1px solid #ebebeb;
    padding: 20px 0;
    float: left;
    width: calc(100% - 80px);
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 200%;
      height: 1px;
      background: #ebebeb;
      left: -100%;
      bottom: 0;
    }

    li {
      float: left;
      margin-right: 10px;

      &.active {
        a {
          color: $main-color;
          text-shadow: 0 0 0 $main-color;
        }
      }
    }
  }

  .link-all {
    font-size: 1rem;
    float: left;
    border-top: 1px solid #ebebeb;
    padding: 20px 0;
    width: 80px;
    margin-bottom: 40px;
  }

  [id^='brand-'] {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
  }

  dl {
    white-space: normal;
    font-size: 1rem;
    margin-bottom: 20px;

    dt {
      font-size: 15px;
      text-transform: uppercase;
      margin: 0 0 29px;
    }

    .bare-list {
      li {
        margin: 0 0 7px;
      }
    }
  }
}

//fancybox

.fancybox-overlay {
  background: rgba(208, 225, 248, 0.85);
}

.fancybox-skin {
  background: #fff;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

.fancybox-close {
  width: 26px;
  height: 26px;
  top: 2px;
  right: 2px;
  background: none;

  &:before {
    font-family: 'BaseIcons';
    font-size: 26px;
    line-height: 1;
    content: "\e20f";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  }
}

.webforms-callback {
  padding: 40px;
  box-sizing: border-box;

  @include bp(min-width, 768px) {
    width: 510px;
  }

  h2, .callbacks-welcome-message {
    color: #42536a;
    text-align: center;
    font-family: 'Open Sans', arial;
    margin: 0;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }

  .callbacks-welcome-message {
    font-size: 14px;
    margin-bottom: 40px;

    @include bp(max-width, $bp-tiny) {
      margin-bottom: 20px;
    }
  }

  .buttons-set {
    padding-top: 37px;
  }

  .field {
    label {
      display: none;
    }

    .validation-advice {
      padding: 0;
      text-align: center;
      width: 264px;
      max-width: 100%;
      margin: 0 auto;
      color: #f68d91;
    }

    input {
      height: 44px;
      width: 264px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
      border-width: 0 0 1px;
      font-size: 14px;
      color: rgba(66, 83, 106, 0.6);

      &:focus {
        box-shadow: none !important;
      }

      &.validation-failed {
        border-color: #ed1c24 !important;
      }
    }
  }

  .buttons-set {
    .button {
      display: block;
      margin: 0 auto;
      float: none;
    }
  }
}

.product-counter {
  .spinner:not(.ui-spinner-input) {
    opacity: 0;
  }
}

.ratings{
  //min-height: 16px;
  .rating-box{
    width: 90px;
    height: 12px!important;
    background-size: 19px!important;
    .rating {
      height: 12px!important;
      background-size: 19px!important;
      background-position-y: bottom;
    }
  }
  .rating-links{
    span.separator{
      display: none;
    }
    a{
      &:last-child{
        display: none;
      }
    }
  }
}

.products-grid {
  .add-to-links {
    li {
      button {
        border: none;
        background: none !important;
        padding: 0;
        cursor: pointer;

        &:before {
          font-size: 40px;
          color: #515050;
        }

        &:hover {
          &:before {
            color: #2e2e2e;
          }
        }
      }
    }
  }
}


.products-grid,
.product-img-column {
  .sticker.out-of-stock {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    overflow: hidden;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      position: absolute;
      display: block;
      padding: 3px 0;
      background-color: #000;
      width: 120px;
      text-align: center;
      white-space: nowrap;
      transform: translate(-10px, -10px) rotate(-45deg);
    }
  }
}

.product-img-column {
  .sticker.out-of-stock {
    z-index: 99;
  }
}

.products-grid, .products-list{
  .item-inner{
    &.stock{
      opacity: .3;
      &:hover{
        opacity: 1;

      }
    }
  }
}


.mini-products-list {
  max-height: 230px;
  padding-right: 40px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;

    &:horizontal {
      height: 4px;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    //border-left: 1px solid #ccc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 4px;

    &:hover {
      background-color: transparent;
    }
  }
}

#compare-items {
  max-height: 180px;
  padding-bottom: 10px;

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cmsadvanced-page-view {
  .box-reviews {
    .view-mode {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      label {
        font-size: 16px;
        margin-right: 20px;
      }

      span,
      button {
        background: none;
        border: none;
        font-size: 16px;
        padding: 5px 10px;
        margin: 0 5px;
        position: relative;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 0;
          transition: width 0.3s;
          background: #d0021b;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      span {
        cursor: default;

        &:after {
          width: 100%;
        }
      }
    }

    .reviews {
      .review-item {
        height: auto !important;
        display: flex;
        flex-wrap: wrap;
        padding: 50px 0;

        &:not(:first-child) {
          border-top: 1px solid #515050;
        }

        .image-block {
          width: 155px;
          margin-right: 45px;
        }

        .info-block {
          width: calc(100% - 200px);
          padding-right: 40px;
          box-sizing: border-box;

          .top-part {
            margin-bottom: 24px;
            display: flex;
            width: 100%;
            align-items: center;

            > div {
              padding: 0 10px;
              border-left: 1px solid rgba(0, 0, 0, 0.1);

              &:first-child {
                padding-left: 0;
                border-left: none;
              }
            }

            .user-name {
              font-size: 18px;
              font-weight: bold;
              line-height: 1.33;
              text-align: left;
              color: #000000;
            }

            .review-from,
            .date {
              font-size: 14px;
              letter-spacing: 0.7px;
              color: #000000;
              opacity: 0.5;
            }
          }
          .description {
            font-size: 16px;
            line-height: 1.56;
            letter-spacing: 0.5px;
            text-align: left;
            color: #000000;
          }
        }
        
        .bottom-block {
          margin-top: 24px;
          width: 100%;

          .name {
            font-size: 16px;
            letter-spacing: 0.7px;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
          .price-block {
            font-size: 18px;
            //font-weight: bold;
            letter-spacing: 0.5px;
            text-align: left;
            color: #000000;

            .price-currency {
              font-weight: bold;
            }

            .old-price {
              font-size: 14px;
              color: rgba(0,0,0,0.5);
            }
          }
        }

        .answer-block {
          margin-left: 40px;
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #515050;
          display: block;
          width: 100%;

          .title {
            font-size: 16px;
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
          }
          .text {
            font-size: 14px;
            display: block;
          }
        }

        @include bp(max-width, 1200px){
          .image-block {
            width: 130px;
            margin-right: 20px;
          }

          .info-block {
            width: calc(100% - 150px);
          }
        }

        @include bp(max-width, 1100px){
          .info-block {
            .top-part {
              flex-wrap: wrap;

              .user-name {
                width: 100%;
                margin-bottom: 15px;
              }

              .review-from {
                padding-left: 0;
                border-left: none;
              }
            }
          }
        }

        @include bp(max-width, 640px){
          .image-block {
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
            //text-align: center;
            max-height: 200px;

            img {
              max-height: 200px;
            }
          }

          .info-block {
            width: 100%;
            padding: 0;
          }
        }
      }
    }

    .review-form {
      max-width: 980px;
      margin: 50px auto 40px;

      .inputs-set,
      .textarea-set {
        .input-box {
          margin: 0 0 20px;
          @include cleaner;

          label {
            width: 100px;
            float: left;
            font-weight: bold;
            position: relative;
            z-index: 0;
            margin: 0 0 2px 0;
            font-size: 16px;
            line-height: 1.56;
            letter-spacing: 0.5px;
            text-align: left;
            color: #000;
          }

          input,
          textarea {
            width: calc(100% - 100px);
            float: left;
            clear: none;
            border-color: #a399a5;
            transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
          }
        }
      }

      .textarea-set {
        .input-box {
          textarea {
            height: 120px;
          }
        }
      }
    }
  }

  .pager {
    padding: 0;

    .pages {
      > strong {
        display: none;
      }

      li {
        border: none;
        width: 38px;
        height: 38px;
        margin: 0;
        border-radius: 2px;
        font-size: 16px;
        color: #000;
        @include trs(border-color .3s ease);

        a {
          width: 38px;
          height: 38px;
          font-size: 16px;
          color: #000;
        }

        &:hover,
        &.current {
          background: #d01f45;
          color: #fff;

          a {
            background: none;
            color: #fff;
          }
        }

        &.next {
          float: right;
        }

        &.previous {
          float: left;
        }

        &.next,
        &.previous {
          width: auto;

          a {
            font-size: 0;

            &:hover {
              background: none;
            }
          }

          span {
            display: none;
          }

          i {
            width: 38px;
            height: 38px;
            line-height: 38px;
            font-size: 30px;
            display: block;
            transform: rotate(90deg);

            &:before {
              content: '\e912';
            }
          }
        }
      }
    }
  }
}
.nav-regular .nav-item.level0 #mini-cart .dropdown-content a {
  line-height: unset;
}

#shopping-cart-table {
  @include bp(max-width, $bp-tiny){
    tr {
      position: relative;

      .btn-remove {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.mini-products-list {
  .product-details {
    color: $b;

    .count {
      margin-left: 10px;
    }
    .item-options {
      margin-left: 70px;

      span {
        &:not(.name){
          font-weight: bold;
        }
      }
    }
  }
}




.ajax-popup-block {
  position: fixed;
  z-index: 999999;
  top: -70px;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #F0F0F0;
  // background-image: repeating-linear-gradient(-45deg, transparent 0px, transparent 20px, rgba(255, 255, 255, 0.05) 20px, rgba(255, 255, 255, 0.05) 40px);
  box-shadow: 0 2px 5px 0 $b;
  color: $b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: top 0.3s;

  &.show {
    top: 0;
  }

  * {
    all: unset;
  }

  .success-msg {
    display: flex;
    align-items: center;

    &:before {
      content: '✓';
      font-size: 36px;
      margin-right: 5px;
      margin-top: -3px;
    }
  }
}
.the-slideshow {
  .owl-controls {
    .owl-buttons {
      height: 67px;
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: $w;

      @include b959 {
        color: $b;
        bottom: auto;
        position: static;
        height: 48px;
      }

      .owl-prev,
      .owl-next {
        stroke:currentColor;
        transform: rotate(0);

        &:before {
          content: none;
        }

        svg {
          display: flex;
          width: 48px;
          height: 48px;
          stroke: currentColor;
          stroke-width: 1.5;
          transform: rotate(0);
        }
      }

      .owl-prev {
        svg {
          transform: rotate(180deg);
          
        }
      }

      .owl-next {
        
      }
    }
  }
}

[id=slideshow-info] {
  font-size: 18px;
  margin-top: 12px;
}

.banner-text-wrap {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%; 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8%;
  box-sizing: border-box;

  @include b959 {
    width: 100%;
    padding-right: 8%;
    top: 65%;
    height: 35%;
    pointer-events: none;
  }
}

.banner-text {
  // flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;
  color: $w;
  box-sizing: border-box;
  font-size: 16px;

  @include bContainer {
    font-size: 1.1vw;
  }

  @include b959 {
    font-size: 1.66vw;
    text-align: center;
    width: 100%;
  }
  

  // @include b639 {
  //   display: none;
  // }

  // .Top {
  //   font-family: $font2;
  //   font-size: 16px;
  //   font-weight: bold;
  // }

  .Big {
    font-family: $font2;
    font-size: 4.06em;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0.153em;
  }

  .Small {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.6em;
    font-weight: bold;
    margin-bottom: 40px;

    @include b959 {
      margin-bottom: 0;
      font-size: 1.5em;
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    background: none;
    border-color: $w;
    color: $w;

    @include b959 {
      display: none;
    }

    &:hover {
      background-color: $w;
      color: $b;
    }
  }
}

.brands-wrap {
  display: flex;
  margin-bottom: 50px;

  @include b767 {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .brands {
    width: 100%;

    // $col: 3;
    // @include b767 {
    //   width: 100%/$col;
    // }

    // $col: 2;
    // @include b479 {
    //   width: 100%/$col;
    // }

    img {
      width: 100%;
    }
  }

  .owl-pagination {
    display: flex;
    justify-content: center;

    .owl-page {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        background-color: $b;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(#000,0.4);
      }
    }

    .active {
      span {
        background-color: #fff;
      }
    }
  }
}

.res-mb {
  margin-bottom: 120px;

  @include b767 {
    margin-bottom: 30px;
  }
}

.catalogsearch-result-index {
  .col-main {
    float: none;
  }
}

.page-content {
  background-color: #e6e8ef;
  padding: 70px;
  font-size: 22px;

  .contacts & {

    iframe {
      width: 100%;
      height: 40vh;
    }
  }

  @include b767 {
    padding: 30px;
  }
}

.text-page-item {
  font-size: 18px;
  display: flex;
  justify-content: space-between;

  @include b767 {
    flex-wrap: wrap;
  }

  &.video {
    display: block;
  }

  .title {
    font-size: 36px;
    font-weight: 900;
    text-transform: uppercase;
    color: #202d7f;
    margin-bottom: 30px;
  }

  .image-block {
    flex-basis: 30%;

    @include b959 {
      flex-basis: calc(50% - 20px);
    }

    @include b767 {
      flex-basis: 100%;

      img {
        width: 100%;
      }

      &:last-child {
        order: -1;
      }
    }
  }

  .info-block {
    flex-basis: calc(70% - 100px);

    @include b959 {
      flex-basis: calc(50% - 20px);
    }

    @include b767 {
      flex-basis: 100%;
    }

    .banner-text {
      color: $b;
      display: block;
    }
  }


  .video-block {
    
    $w169: 16/9;
    
    position: relative;
    width: 100%;
    padding-bottom: #{100%/$w169};

    iframe {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

    }
  }

  &.slider {
    .owl-controls {
      margin-top: 20px;
    }
  }
}

.slider-item {
  display: flex;
  background-color: #e6e8ef;

  @include b639 {
    flex-wrap: wrap;
  }

  .image-block {
    flex-basis: 50%;
    display: flex;
    align-items: center;

    @include b639 {
      flex-basis: 100%;
    }

    img {
      width: 100%;
    }
  }

  .info-block {
    flex-basis: 50%;
    padding: 70px 80px 80px;
    box-sizing: border-box;

    @include b1023 {
      padding: 30px;
    }

    @include b639 {
      flex-basis: 100%;
    }

    &.dot {
      // height: 100%;
    }

    .title {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
}


  .page .homepage-productslider .itemslider-wrapper,
  .page .homepage-unitopblog,
  .page .the-slideshow-wrapper,
  .page .homepage-hotcategories {
    margin-left: 0;
    margin-right: 0;
  }

  .mini-wishlist .mini-wishlist-content .actions {
    display: flex;
    justify-content: center;
  }

.opcheckout-agreement-info {
  clear: both;
  margin-bottom: 30px;

  .agree-checkbox {
    margin-left: 3px;

    input {
      display: none;

      &:checked {
        + label {
          &:before {
            @extend %checkbox;
            
          }
        }
      }
    }

    label {
      position: relative;
      padding-left: 45px;
      cursor: pointer;

      &:hover {
        &:before {
          @extend %checkbox;
        }
      }

      &:before {
          content: '';
          background-size: contain;

          position: absolute;
          top: -3px;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid rgba($b,0.2);
          box-sizing: border-box;
          color: transparent;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      &:after {
        content: none;
      }
    }
  }

  .agree-link {
    text-decoration: underline;
    font-weight: bold;
  }
}


#tab-description {
  display: none;
}

#acctab-description {
  display: none;

  + div {
    display: none !important;
  }
}