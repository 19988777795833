#nav,
#nav-left {
  .section-title {
    font-size: 1.4em;
    margin-top: 10px;
  }

  .nav-panel-inner {
    padding: 0;
  }

  .nav-panel--dropdown {
    box-sizing: border-box;
  }

  a:not(.mini-cart-heading) {
    .caret {
      border: none;
      transform: rotate(-90deg);
      position: absolute;
      top: 50%;
      margin: -8px 0 0 0;
      right: 5px;
    }
  }

  .nav-item {
    .nav-panel--dropdown {
      .nav-item.mega {
        .nav-submenu {
          .nav-item--only-subcategories {
            li {
              a {
                padding: 4px 20px 5px 32px !important;
              }

              &:after {
                left: 15px;
              }
            }
          }
        }
      }
    }
  }

  .nav-item--only-subcategories {
    &.simple {
      .nav-submenu {
        li {
          a {
            padding: 4px 20px 5px 32px !important;

            &:after {
              left: 15px;
            }
          }
        }
      }
    }
  }

  .itemslider-wrapper .owl-controls .owl-buttons {
    top: -38px;
  }

  [id^="nav-holder"] {
    .caret {
      display: none;
    }
  }
}

.header-regular {
  #nav,
  #nav-left {
    > .nav-item {
      > .nav-panel--dropdown {
        padding: 0;
        

        > .nav-item {
          a {
            display: block;
            padding: 0 20px 0 12px;
            line-height: 50px;
          }

          .nav-item {
            a {
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}

.sticky-header {
  #nav,
  #nav-left {
    > .nav-item {
      > .nav-panel--dropdown {
        box-shadow: 0 5px 10px rgba(#000,0.3);
      }
    }
  }
}

#header-nav {
  @include bp(max-width, 959px) {
    .inner-container {
      padding: 0;
    }

    .mobnav-trigger {
      background-color: $b;
      color: $w;

      &:hover,
      &.active {
        background: $gray;
        color: $w;
      }

      .line {
        background: #fff;
      }
    }
  }
}

.header-mobile {
  a.skip-link {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #000;
    }

    &.skip-active {
      color: $brown;
      
      &:hover {
        // color: #000;
      }
    }

    .icon {
      //outline: 1px solid red;
      margin: 0;

      &:after {
        display: none;
      }
    }

    &.skip-nav {
      .icon {
        font-family: 'BaseIcons' !important;
        font-size: 26px !important;
      }
    }

    &.skip-account {
      .icon {
        font-size: 26px !important;
      }
    }
  }

  .header-primary-container {
    .right-column {
      #search-wrapper-regular {
        margin-right: 0;
      }
    }
  }
}

#nav {
  .title,
  .box-categorymegamenus {
    display: none;
  }

  &.nav-mobile {
    .nav-item {
      &.item-active {
        .level-top {
          background: rgba(0, 0, 0, 0.05);
          // color: #fff;
        }

        .opener {
          // color: #fff;
        }
      }

      .level-top {
        &:hover {
          background: rgba(0, 0, 0, 0.05);
          // color: #fff;

          & ~ .opener {
            // color: #fff;
          }
        }
      }

      &.level0 {
        > noindex > a {
          font-size: 16px;
          text-transform: uppercase;
          background-color: #515050;
          color: #ffffff;
          padding: 15px 10px;
          display: block;
        }
      }
    }
  }

  .categories-list {
    a:not(.skip-link) {
      // color: #fff;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        // color: #fff;
      }
    }
  }
}

.header-regular:not(.sticky-header) {
  #nav:not(.nav-mobile) {
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: 40px;
    margin-left: -1px;
    margin-right: -1px;

    &.centered {
      //justify-content: space-between;
    }

    > li {
      position: static !important;
      width: 100%;
      margin: 0 1px;
      display: flex;

      &.sale {
        > noindex {
          position: relative;
          display: flex;
          width: 100%;
        }
        > noindex > a,
        > a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          position: relative;
          height: 100%;
          display: flex;
          padding: 0 5px;
          text-transform: uppercase;
          font-size: 13px;
          // color: #fff;
          background-color: $llgray;

          span {
            position: relative;
          }

          // &:before {
          //   content: '';
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   background: #d01f45;
          //   transform: skew(-13deg);
          // }

          &:after {
            left: -5px;
          }
        }

        &:hover {
          > noindex > a,
          > a {
            color: $w;
            background: $b;

            &:before {
              background: #eee;
            }
          }
        }
      }

      // > noindex {
      //   width: 100%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   > a {
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //   }
      // }

      > a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $llgray;
        padding: 0 5px;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        // font-weight: bold;

        @include bp(max-width, 1160px){
          padding: 0 10px;
        }

        &:hover {
          background-color: $b;
          color: $w;
        }
        
        .caret {
          display: none;
        }
      }

      > div {
        width: 100%;
        background: #eeeeee;
        border-top: 1px solid #d8d8d8;

        .submenu-wrapper {
          display: flex;
          flex-direction: row-reverse;
          min-height: 200px;
        }

        .box-categorymegamenus,
        .categories-list {
          box-sizing: border-box;
          display: block;

          .title {
            font-size: 13px;
            color: #000000;
            text-transform: uppercase;
            margin-bottom: 20px;
            display: block;
            width: 100%;
            font-weight: bold;
          }
        }

        .categories-list {
          width: 100%;
          padding: 50px 0;
          display: block;
          flex-wrap: wrap;
          align-content: flex-start;

          > .nav-item {
            // width: calc(100% / 6);
            padding: 7px 50px;
            box-sizing: border-box;

            // @include bp(max-width, 1280px){
            //   width: calc(100% / 5);
            // }

            &.level1.nav-item--parent {
              > a {

                &:hover {
                  &:after {
                    content: '';
                  }
                }
                
                &:after {
                  position: absolute;
                  height: 200px;
                  width: calc(100% + 30px);
                  z-index:1;
                }
              }
            }

            a {
              font-family: $font;
              line-height: 17px;
              font-size: 14px;
              letter-spacing: 0.9px;
              color: #000000;

              &:hover {
                //opacity: 0.7;
                color: $brown;
                background: none;
              }

            }

            
          }
        }

        .box-categorymegamenus {
          width: 80%;
          padding: 50px 50px 50px 80px;

          // @include b1399 {
          //   width: 50%;
          // }

          @include bp(max-width, 1280px){
            padding: 40px;
          }

          .categorymegamenus-list {
            display: flex;
            flex-wrap: wrap;
          }

          .item {
            // width: calc(100% / 3);
            width: 140px;
            padding: 0 10px;
            margin-bottom: 25px;
            box-sizing: border-box;

            @include bp(max-width, 1280px){
              width: calc(100% / 2);
            }

            .image-block {
              width: 114px;
              height: 114px;
              border: solid 1px #cacaca;
              border-radius: 50%;
              overflow: hidden;
              margin: 0 auto 10px;
              transition: border-color 0.3s;

              .img {
                width: 110px;
                height: 110px;
                border-radius: 50%;
                overflow: hidden;
                background: #cacaca;
                border: solid 2px #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .name-block {
              font-size: 14px;
              letter-spacing: 0.9px;
              color: #000;
              text-align: center;
            }

            a {
              line-height: 1;

              &:hover {
                color: #98867a;
                .image-block {
                  border-color: currentColor;
                }

                .name-block {
                  color: currentColor;
                }
              }
            }
          }
        }

        .categories-list {
          .box-categorymegamenus {
            width: 75%;

            ~ .categories-list {
              width: 25%;
            }
          }


        }

        .box-categorymegamenus ~ .categories-list {
          width: 20%;
          border-right: 1px solid #d8d8d8;

          // @include b1399 {
          //   width: 50%;
          // }

          > .nav-item {
            // width: calc(100% / 3);

            // @include bp(max-width, 1280px){
            //   width: calc(100% / 2);
            // }
          }
        }

        &.without-category {
          .box-categorymegamenus {
            width: 100%;
            padding: 50px;

            .categorymegamenus-list {
              columns: 6;
              display: block;

              @include bp(max-width, 1280px){
                columns: 5;
              }
            }

            .item {
              width: auto;
              // width: calc(100% / 6);

              // @include bp(max-width, 1280px){
              //   width: calc(100% / 5);
              // }
            }
          }
        }
      }
    }
  }
}

#sticky-nav,
#sticky-catalog-menu {
  display: none;
  visibility: hidden;
}

.sticky-header {
  .dropdown .dropdown-heading.cover > span {
    padding: 0 16px;
  }

  #sticky-catalog-menu {
    display: block;
    float: left;
    visibility: visible;
  }

  .nav-item--home {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 200px;

    a {
      display: flex;
      align-items: center;
      height: 40px;

      &:hover {
        background-color: transparent !important;
      }
    }

    img {
      vertical-align: middle;
    }
  }
}

#sticky-catalog-menu {
  .sticky-main-nav {
    > li {
      display: block;

      a {
        color: #000 !important;
        font-size: 17px;
        font-family: 'Roboto';
      }

      &:hover {
        > a {
          //color: $white;
        }
      }

      > .nav-submenu {
        left: 100% !important;
        top: 0 !important;
        display: none;
        box-shadow: 0 5px 10px rgba(#000,0.3);
      }
    }
  }
}

.nav-regular {
  .classic {
    > .nav-panel--dropdown {
      padding: 0;
    }
  }
  .nav-panel {
    li.mega {
      &:hover {
        > .nav-panel--dropdown {
          // left: 100%;
        }
      }
    }

    li.classic:hover {
      position: static !important;

      > .nav-panel--dropdown {
        left: 20%;
        width: 80%;

        .sticky-header & {
          left: 100%;
          width: auto;
        }
      }
    }
  }

  .nav-submenu--mega > li.classic:hover > .nav-panel--dropdown {
    // left: 100%;
  }


}

.the-slideshow-wrapper {
  @include cleaner;

  .the-slideshow {
    @include bp(max-width, 959px) {
      width: 100%;
    }
  }

  //&.fixed-nav {
  //@include bp(min-width, 960px) {
  //display: table;
  //width: 100%;
  //table-layout: fixed;

  //> div {
  //display: table-cell;
  //vertical-align: top;
  //float: none;
  //}

  //.vert-nav {
  //position: relative;

  //.nav-regular {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  min-height: 100%;
  //}

  //& + .the-slideshow {
  //  float: none !important;
  //}
  //}
  //}
  //}
}

#nav-left {
  > li {
    position: relative;

    > .nav-submenu {
      left: 100% !important;
      top: 0 !important;
    }

    .nav-submenu {
      padding: 12px 0;
      box-sizing: border-box;

      .caret {
        border: none;
      }
    }
  }

  .nav-item.first > a:before {
    display: none;
  }
}

#nav {
  > .nav-item {
    > .left-drop {
      left: auto !important;
      right: 0;
    }

    .nav-submenu.left-drop {
      .nav-item--parent {
        > a {
          // padding: 15px 12px 15px 25px;

          .caret {
            right: auto;
            // left: 5px;
            // margin-top: 1px;
            // transform: rotate(90deg);
          }
        }
      }

      > li:hover {
        > .nav-panel--dropdown {
          left: auto;
          right: 100% !important;
        }
      }
    }
  }
}