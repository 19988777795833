.my-account {
  label[for="subscription"],
  label[for="change_password"] {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    padding-top: 2px;

    &:hover {
        &:before {
            @extend %checkbox;
        }
    }

    &:before {
        content: '';
        background-size: contain;

        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid rgba($b,0.2);
        box-sizing: border-box;
        color: transparent;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:after {
        content: none;
    }
  }
  [type="checkbox"]:checked {
    +label[for="subscription"],
    +label[for="change_password"] {
      &:before {
          @extend %checkbox;
      }
    }
  }
  

  .box-account {
    .box {
      margin-bottom: 30px;
    }
  }

  .addresses-primary,
  .addresses-additional {
    min-width: 250px;
    float: left;
  }
  .addresses-primary {
    margin-right: 2%;
  }

  .col-1,
  .col-2 {
    min-width: 250px;
    margin-bottom: 20px;
  }

  .col-1 {
    margin-right: 2%;
    border: none;
  }
  .col-2 {
    float: left;
  }

  @include bp(max-width, $bp-smallest) {
    .form-list {
      .field,
      .input-text {
        width: 100%;
      }
    }

    .chosen-container {
      min-width: 100%;
    }
  }

  .buttons-set {
    .button {
      float: none;
      margin-left: 0;
    }
  }

  .box-info {
    .box-content {
      div {
        >span {
          display: inline-block;
          vertical-align: middle;

          &:first-child {
            font-weight: bold;
            min-width: 100px;
          }
        }
      }
    }
  }
}

.block-poll {
  .actions {
    .button {
      width: 100%;
    }
  }
}

.my-wishlist {
  .data-table tr.even {
    background: #f8f8f8;
  }

  .add-to-cart-alt {
    // text-align: center;

    .input-text {
        height: 41px;
        margin-right: 20px;
    }
  }

  .qty {
    vertical-align: top;
    margin-bottom: 5px;
  }

  .btn-cart {
    vertical-align: top;
  }

  @include bp(max-width, $bp-tiny) {
    .price-box,
    .product-image,
    .product-name {
      text-align: center;
    }

    .btn-remove {
      width: auto;
      height: auto;
      font-size: 1rem;

      &:before {
        vertical-align: bottom;
      }
    }
  }
}

.wishlist-actions {
  padding: 10px 0;
}

.account-login {
  .new-users {
    margin-right: 0;

    button {
      float: left;
    }
  }

  .form-list li {
    margin-bottom: 22px;
  }

  .registered-users {
    margin-left: 0;
  }

  .registered-users {
    .buttons-set {
      margin-bottom: 34px;
    }

    button {
      width: 150px;
      margin-right: 15px;
      margin-bottom: 10px;
      margin-left: 0;
      float: left;
    }

    @include bp(max-width, $bp-smallest) {
      button,
      .input-text {
        width: 100%;
        margin: 0 0 10px;
      }
    }
  }

  .button {
    min-height: 50px;
    font-weight: bold;
  }
}

#login-form {
  .btn-remember-pass {
    @extend %btn-gray;
    padding-top: 16px;
  }
}

.account-login h2,
#opc-login h3,
.fieldset .legend,
.multiple-checkout .col2-set h2.legend,
.info-set h2.legend {
  font-size: 2rem;
  padding-bottom: 26px;
  border-bottom: 1px solid #dbd6dc;
  margin-bottom: 15px;

  @include bp(max-width, 1199px) {
    font-size: 1.35rem;
  }
}
.account-login,
.account-create {
  h4 {
    font-size: 14px;
  }

  .social-links {
    margin: 0;

    .social-list {
      margin: 0;
      margin: -10px 0 0 -10px;
      

      li {
        margin: 10px 0 0 10px;

        a {
          border: 2px solid $b;

          &:hover {
            background-color: $b;
            color: $w;
          }
        }
      }
    }
  }
}

.account-create {
  .actions {
    padding-top: 28px;
    margin-bottom: 42px;
  }

  .fieldset {
    margin-bottom: 0;
  }

  label[for="is_subscribed"] {
    &:hover {
        &:before {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60' height='60'><path fill='#fff' d='M40.563 17.5l-11.525 17.605-11.3-7.395-2.737 4.18 15.483 10.137 14.262-21.787z'></path></svg>");
            background-color: $b;
            border-color: $b;
            color: #fff;
        }
    }

    &:before {
        content: '';
        background-size: contain;

        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid rgba($b,0.2);
        box-sizing: border-box;
        color: transparent;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:after {
        content: none;
    }
  }

  input:checked + label[for="is_subscribed"] {
    &:before {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60' height='60'><path fill='#fff' d='M40.563 17.5l-11.525 17.605-11.3-7.395-2.737 4.18 15.483 10.137 14.262-21.787z'></path></svg>");
        background-color: $b;
        border-color: $b;
        color: #fff;
    }
  }
}

.my-account,
.account-edit-form,
.forgotpassword-form {
  .actions {
    padding: 22px 0;
  }
}

#my-orders-table {
  .nobr {
    white-space: nowrap !important;
  }

  @include bp(max-width, $bp-tiny) {
    td {
      padding: 0;
    }

    .product-name {
      font-size: 1rem;
      font-weight: bold;
    }

    .price-excl-tax {
      display: inline-block;
      margin-top: 10px;
    }

    td.last {
      .price {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}

@include bp(max-width, $bp-tiny){
    .customer-account {
        .block-account {
            position: relative;

            .block-content {
                width: 100%;
                box-sizing: border-box;
                display: none;
                background: #fff;
                position: absolute;
                z-index: 10;
                padding: 10px 10px 20px;
                box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.3);

                ul {
                    list-style: none;
                }
            }

            .block-title.active + .block-content {
                display: block;
            }
        }
    }
}

@include bp(max-width, 1023px){
  html.touch {
    .customer-account {
        .block-account {
            position: relative;

            .block-title {
                &:after {
                    content: '\e260';
                    font-family: BaseIcons;
                    display: inline-block !important;
                    -webkit-font-smoothing: antialiased;
                    transition: .3s ease;
                    vertical-align: middle;
                    font-size: 18px;
                    line-height: 1;
                }
            }

            .block-content {
                width: 100%;
                box-sizing: border-box;
                display: none;
                background: #fff;
                position: absolute;
                z-index: 10;
                padding: 10px 10px 20px;
                box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.3);

                ul {
                    list-style: none;
                }
            }

            .block-title.active + .block-content {
                display: block;
            }
        }
    }
  }
}

.customer-account {
    .col-left {
        width: 260px;
    }
    .col-main {
        width: calc(100% - 260px);
    }

    @include bp(max-width, 880px){
        .inner-container {
            padding: 0;
        }
    }

    @include bp(max-width, $bp-tiny){
        .col-left,
        .col-main {
            width: 100%;
        }
    }
}